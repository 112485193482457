import { FC, useEffect } from "react";
import styled from "styled-components/macro";
import { BodyS, Button } from "@salutejs/plasma-b2c";
import { backgroundPrimary } from "@salutejs/plasma-tokens-b2c";

import { useWindowSize } from "../../utils/hooks";
import { useStateContext } from "../../utils/context";

import { Icon } from "../Icon";

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
`;

const ButtonSetting = styled(Button)`
  width: auto;
  height: 38px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 4px 8px 4px 4px;
  background: ${backgroundPrimary};
`;

type Props = {
  openShadersModal?: () => void;
  toggleAutoRotate: () => void;
};

export const SettingsPanel: FC<Props> = ({
  toggleAutoRotate,
  openShadersModal,
}) => {
  const { isMobile } = useWindowSize();

  const { isFullScreenModel, setIsFullScreenModel } = useStateContext();

  useEffect(() => {
    if (isMobile) {
      setIsFullScreenModel(false);
    }
  }, [isMobile, setIsFullScreenModel]);

  return (
    <Wrapper>
      <ButtonSetting
        disabled
        text={<BodyS>Модель</BodyS>}
        contentLeft={<Icon name="cube_24" />}
      />

      <ButtonSetting
        text={<BodyS>Автовращение</BodyS>}
        contentLeft={<Icon name="play_24_line" />}
        onClick={toggleAutoRotate}
      />

      {!isMobile && (
        <ButtonSetting
          text={
            <BodyS>{isFullScreenModel ? "Свернуть" : "На весь экран"}</BodyS>
          }
          contentLeft={<Icon name="full_screen_square_24_line" />}
          onClick={() => setIsFullScreenModel((prev) => !prev)}
        />
      )}

      {openShadersModal && (
        <ButtonSetting onClick={openShadersModal}>shaders</ButtonSetting>
      )}
    </Wrapper>
  );
};
