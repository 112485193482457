import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { axiosScene } from "../utils/axios";
import { SIZES } from "../utils/breakpoints";
import { IModel, ModelPlyI, PostMessageData } from "../utils/types";
import { SECRET_TOKEN } from "../utils/constants";

import { Wrapper } from "../components/PageWrapper";
import { ModelGlb } from "../components/ModelGlb";

const StyledPageWrapper = styled(Wrapper)`
  display: flex;
  padding-top: 0;
  grid-template: none;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: ${SIZES.sm}px) {
    flex-direction: column;

    > div {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export const IframePageGlb: FC = () => {
  const { uid } = useParams<{ uid: string }>();

  const [model, setModel] = useState<IModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [isShowPage, setIsShowPage] = useState<boolean>(false);

  const getModel = async (uid?: string) => {
    setIsLoading(true);
    if (!uid) {
      setIsLoading(false);
      setError("error");
      return;
    }

    try {
      const { data } = (await axiosScene.get(`/model/${uid}`)) as {
        data: ModelPlyI[];
      };

      setIsLoading(false);

      if (!data?.[0]) {
        return;
      }

      setModel(data[0]);
    } catch (error) {
      setError("error");
    }
  };

  useEffect(() => {
    getModel(uid);
  }, [uid]);

  useEffect(() => {
    const handleMessage = ({ data }: MessageEvent<PostMessageData>): void => {
      console.log("---data", data);
      if (data.authentication) {
        if (data.authentication.token === SECRET_TOKEN) {
          setIsShowPage(true);
        }
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  if (!isShowPage) {
    return null;
  }

  return (
    <StyledPageWrapper>
      <ModelGlb model={model} isLoading={isLoading} />
    </StyledPageWrapper>
  );
};
