import { FC } from "react";
import { useParams } from "react-router-dom";

import { Cards } from "../components/Cards";
import { PageWrapper } from "../components/PageWrapper";
import { SidePanel } from "../components/SidePanel";

import { useLazyModels } from "../utils/hooks";

export type user = {
  nickname: string;
};

export const UserModelPage: FC = () => {
  const { nickname } = useParams<user>();

  const showModels = useLazyModels(`/${nickname}`);

  return (
    <PageWrapper>
      <SidePanel />
      <Cards models={showModels} />
    </PageWrapper>
  );
};
