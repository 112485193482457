import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import CodeEditor from "@uiw/react-textarea-code-editor";

import {
  tertiary,
  surfaceCard,
  background,
  whiteSecondary,
} from "@salutejs/plasma-tokens-b2c";
import { Button, TextField, H5, H2, Checkbox } from "@salutejs/plasma-b2c";
import { TextFieldView } from "@salutejs/plasma-b2c/components/TextField/TextField";

import { SIZES } from "../../utils/breakpoints";
import { PATHS } from "../../utils/constants";

import { Icon } from "../Icon";

const Background = styled.div`
  top: 104px;
  background: ${background};
  border-radius: 20px;
  position: absolute;
  width: 416px;
  height: auto;
  z-index: 2;

  @media (max-width: ${SIZES.md}px) {
    width: calc(100% - 32px);
    top: 16px;
  }
`;

const CodeEditorStyled = styled(CodeEditor)`
  width: 100% !important;
`;

const Wrapper = styled.div`
  background: ${surfaceCard};
  backdrop-filter: blur(72px);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  padding: 20px;

  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;

  > * {
    &:last-child {
      width: 100%;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Block = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  border-bottom: 1px solid ${tertiary};

  ${H5} {
    width: 100%;
    margin-bottom: 8px;
  }

  > * {
    &:not(:first-child) {
      width: calc(50% - 8px);
    }
  }
  &:nth-last-child(-n + 2) {
    border: none;
    padding-bottom: 0;
  }
`;

const ColorValue = styled.input`
  max-width: 33px;
  min-width: 33px;
  max-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 !important;
  overflow: hidden;
`;

const StyledOpenCode = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  > span {
    ${H5} {
      width: auto;
      margin: 0;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 6px;

      > ${H5} {
        color: ${whiteSecondary};
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
`;

type Props = {
  uid?: string;
  isOpenWidgetModal: boolean;
  closeWidgetModal: () => void;
};

export const WidgetModal: FC<Props> = ({
  uid,
  isOpenWidgetModal,
  closeWidgetModal,
}) => {
  const [frameWidth, setFrameWidth] = useState<number>(300);
  const [frameHeight, setFrameHeight] = useState<number>(380);
  const [frameAutoRotate, setFrameAutoRotate] = useState<boolean>(false);
  const [frameBackground, setFrameBackground] = useState<string>("#000000");
  const [frameColor, setFrameColor] = useState<string>("#ffffff");
  const [frameCode, setFrameCode] = useState<string>("");
  const [showCode, setShowCode] = useState<boolean>(false);

  const copyFrame = async () => {
    try {
      await navigator.clipboard.writeText(frameCode);
    } catch (error) {}
  };

  useEffect(() => {
    if (!uid) {
      return;
    }

    const options = {
      width: frameWidth,
      height: frameHeight,
      autoRotate: frameAutoRotate,
      background: frameBackground,
      color: frameColor,
    };

    const filteredOptions = Object.entries(options).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: String(value) }),
      {}
    );

    const params = new URLSearchParams(filteredOptions);

    const src = `${window.origin}${PATHS.iframe.replace(
      ":uid",
      uid
    )}?${params}`;

    setFrameCode(`<iframe src="${src}" width="${frameWidth}" height="${frameHeight}">
    Ваш браузер не поддерживает плавающие фреймы!
</iframe>`);
  }, [
    frameWidth,
    frameHeight,
    frameAutoRotate,
    frameBackground,
    frameColor,
    uid,
  ]);

  if (!isOpenWidgetModal) {
    return null;
  }

  return (
    <Background>
      <Wrapper>
        <Header>
          <H2>Виджет</H2>

          <Button
            size="s"
            onClick={closeWidgetModal}
            contentLeft={<Icon name="close_24_line" />}
          />
        </Header>

        <Block>
          <H5>Размеры</H5>

          <TextField
            caption="Ширина"
            size="s"
            view={TextFieldView.innerLabel}
            value={frameWidth}
            onChange={({ target: { value } }) => setFrameWidth(Number(value))}
          />

          <TextField
            caption="Высота"
            size="s"
            view={TextFieldView.innerLabel}
            value={frameWidth}
            onChange={({ target: { value } }) => setFrameHeight(Number(value))}
          />
        </Block>

        <Block>
          <H5>Оформление</H5>

          <TextField
            caption="Цвет фона"
            size="s"
            view={TextFieldView.innerLabel}
            value={frameBackground}
            contentRight={
              <ColorValue
                type="color"
                value={frameBackground}
                onChange={({ target: { value } }) => setFrameBackground(value)}
              />
            }
          />

          <TextField
            caption="Цвет текста"
            size="s"
            view={TextFieldView.innerLabel}
            value={frameColor}
            contentRight={
              <ColorValue
                type="color"
                value={frameColor}
                onChange={({ target: { value } }) => setFrameColor(value)}
              />
            }
          />
        </Block>

        <Block>
          <H5>Сцена</H5>

          <Checkbox
            label="Автовращение модели"
            value={frameBackground}
            onChange={({ target: { checked } }) => setFrameAutoRotate(checked)}
          />
        </Block>

        <Block>
          <StyledOpenCode
            view="clear"
            onClick={() => setShowCode((prev) => !prev)}
          >
            <H5>Код</H5>

            <div>
              <H5>{showCode ? "Скрыть" : "Показать"}</H5>

              <Icon
                name={
                  showCode ? "disclosure_down_16_line" : "disclosure_up_16_line"
                }
              />
            </div>
          </StyledOpenCode>

          {showCode && (
            <CodeEditorStyled
              language="html"
              value={frameCode}
              style={{
                fontSize: 10,
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          )}
        </Block>

        <Button text="Копировать виджет" view="primary" onClick={copyFrame} />
      </Wrapper>
    </Background>
  );
};
