import axiosInstance from "axios";

export const axiosScene = axiosInstance.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://scenes.sxr.su/api/",
});

export const axiosCdnBaseUrl =
  process.env.REACT_APP_CDN_URL || "https://cdn.sxr.su/";

export const axiosCdn = axiosInstance.create({
  baseURL: axiosCdnBaseUrl,
});
