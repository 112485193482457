export const scrollTarget = Array.from(
  { length: 12 },
  (_, i) => 100 + i * 1500
);

export const duration = 0.5;

export const transition = `transition: all ${duration}s;`;

export const widthOrHeightInitial =
  window.innerWidth > window.innerHeight
    ? "width: initial;"
    : "height: initial;";
