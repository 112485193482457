import { FC } from "react";
import styled from "styled-components/macro";
import { SIZES } from "../../utils/breakpoints";
import { useWindowSize } from "../../utils/hooks";
import { IModel } from "../../utils/types";
import { Card } from "../Card";
import { generatePlace } from "./utils";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-auto-rows: 2vw;
  gap: 16px;

  @media (max-width: ${SIZES.lg}px) {
    gap: 12px;
  }
`;

const WrapperCard = styled.div<{ place: string }>`
  ${({ place }) => place}

  >div {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${SIZES.sm}px) {
    aspect-ratio: 230/320;
  }
`;

interface Props {
  models: IModel[];
}

export const BrokenLine: FC<Props> = ({ models }) => {
  const { width } = useWindowSize();

  return (
    <Wrapper>
      {models.map((model, i) => {
        return (
          <WrapperCard key={model.uid} place={generatePlace(i, width)}>
            <Card model={model} isAspectRatio={false} />
          </WrapperCard>
        );
      })}
    </Wrapper>
  );
};
