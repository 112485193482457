import { FC, useEffect, useMemo, useState } from "react";

import { Promo } from "../components/Promo";
import { PageWrapper } from "../components/PageWrapper";
import { SidePanel } from "../components/SidePanel";
import { BrokenLine } from "../components/BrokenLine";
import { useLazyModels, useWindowSize } from "../utils/hooks";
import { DISABLED_PROMO } from "../utils/constants";
import { PromoMobile } from "../components/Promo/PromoMobile";

const firstModelUid = "c3fdea41-39fa-4bdf-af5e-4c1feb281120";

export const HomePage: FC = () => {
  const showModels = useLazyModels("/", firstModelUid);
  const { width, height } = useWindowSize();

  const isDesktop = useMemo(() => width > height, [width, height]);

  const [disabledPromo, setDisabledPromo] = useState<boolean>(false);

  useEffect(() => {
    const promo = localStorage.getItem(DISABLED_PROMO);
    setDisabledPromo(!!promo);
  }, []);

  if (!disabledPromo) {
    if (isDesktop) {
      return <Promo showModels={showModels} />;
    }

    return (
      <PageWrapper paddingMobile="8px 8px 0">
        <PromoMobile showModels={showModels} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <SidePanel />
      <BrokenLine models={showModels} />
    </PageWrapper>
  );
};
