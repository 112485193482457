import { FC, useEffect } from "react";
import styled from "styled-components/macro";

import { SIZES } from "../../utils/breakpoints";

export const Wrapper = styled.div<{ paddingMobile?: string }>`
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  box-sizing: border-box;

  padding: 28px 64px 0;

  display: flex;

  @media (max-width: ${SIZES.xl}px) {
    padding: 28px 56px 0;
  }

  @media (max-width: ${SIZES.sm}px) {
    padding: ${({ paddingMobile }) => paddingMobile || "16px 16px 0"};

    flex-direction: column;
    position: inherit;
  }
`;
interface Props {
  children: React.ReactNode;
  paddingMobile?: string;
}

export const PageWrapper: FC<Props> = ({ children, paddingMobile }) => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return <Wrapper paddingMobile={paddingMobile}>{children}</Wrapper>;
};
