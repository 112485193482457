import { Camera, Self } from "./types";

export const vertexShaderSource = `
precision mediump float;
attribute vec2 position;

attribute vec4 color;
attribute vec3 center;
attribute vec3 covA;
attribute vec3 covB;

uniform mat4 projection, view;
uniform vec2 focal;
uniform vec2 viewport;

varying vec4 vColor;
varying vec2 vPosition;

mat3 transpose(mat3 m) {
  return mat3(
      m[0][0], m[1][0], m[2][0],
      m[0][1], m[1][1], m[2][1],
      m[0][2], m[1][2], m[2][2]
  );
}

void main () {
  vec4 camspace = view * vec4(center, 1);
  vec4 pos2d = projection * camspace;

  float bounds = 1.2 * pos2d.w;
  if (pos2d.z < -pos2d.w || pos2d.x < -bounds || pos2d.x > bounds
   || pos2d.y < -bounds || pos2d.y > bounds) {
      gl_Position = vec4(0.0, 0.0, 2.0, 1.0);
      return;
  }

  mat3 Vrk = mat3(
      covA.x, covA.y, covA.z, 
      covA.y, covB.x, covB.y,
      covA.z, covB.y, covB.z
  );

  mat3 J = mat3(
      focal.x / camspace.z, 0., -(focal.x * camspace.x) / (camspace.z * camspace.z), 
      0., -focal.y / camspace.z, (focal.y * camspace.y) / (camspace.z * camspace.z), 
      0., 0., 0.
  );

  mat3 W = transpose(mat3(view));
  mat3 T = W * J;
  mat3 cov = transpose(T) * Vrk * T;
  
  vec2 vCenter = vec2(pos2d) / pos2d.w;

  float diagonal1 = cov[0][0] + 0.3;
  float offDiagonal = cov[0][1];
  float diagonal2 = cov[1][1] + 0.3;

float mid = 0.5 * (diagonal1 + diagonal2);
float radius = length(vec2((diagonal1 - diagonal2) / 2.0, offDiagonal));
float lambda1 = mid + radius;
float lambda2 = max(mid - radius, 0.1);
vec2 diagonalVector = normalize(vec2(offDiagonal, lambda1 - diagonal1));
vec2 v1 = min(sqrt(2.0 * lambda1), 1024.0) * diagonalVector;
vec2 v2 = min(sqrt(2.0 * lambda2), 1024.0) * vec2(diagonalVector.y, -diagonalVector.x);


  vColor = color;
  vPosition = position;

  gl_Position = vec4(
      vCenter 
          + position.x * v1 / viewport * 2.0 
          + position.y * v2 / viewport * 2.0, 0.0, 1.0);

}
`;

export const fragmentShaderSource = `
precision mediump float;

varying vec4 vColor;
varying vec2 vPosition;

void main () {    
  float A = -dot(vPosition, vPosition);
  if (A < -4.0) discard;
  float B = exp(A) * vColor.a;
  gl_FragColor = vec4(B * vColor.rgb, B);
}
`;

export let defaultViewMatrix = [
  0.47, 0.04, 0.88, 0, -0.11, 0.99, 0.02, 0, -0.88, -0.11, 0.47, 0, 0.07, 0.03,
  6.55, 1,
];

export const getProjectionMatrix = (
  fx: number,
  fy: number,
  width: number,
  height: number
) => {
  const znear = 0.2;
  const zfar = 200;
  return [
    [(2 * fx) / width, 0, 0, 0],
    [0, -(2 * fy) / height, 0, 0],
    [0, 0, zfar / (zfar - znear), 1],
    [0, 0, -(zfar * znear) / (zfar - znear), 0],
  ].flat();
};

export const multiply4 = (a: number[], b: number[]) => {
  return [
    b[0] * a[0] + b[1] * a[4] + b[2] * a[8] + b[3] * a[12],
    b[0] * a[1] + b[1] * a[5] + b[2] * a[9] + b[3] * a[13],
    b[0] * a[2] + b[1] * a[6] + b[2] * a[10] + b[3] * a[14],
    b[0] * a[3] + b[1] * a[7] + b[2] * a[11] + b[3] * a[15],
    b[4] * a[0] + b[5] * a[4] + b[6] * a[8] + b[7] * a[12],
    b[4] * a[1] + b[5] * a[5] + b[6] * a[9] + b[7] * a[13],
    b[4] * a[2] + b[5] * a[6] + b[6] * a[10] + b[7] * a[14],
    b[4] * a[3] + b[5] * a[7] + b[6] * a[11] + b[7] * a[15],
    b[8] * a[0] + b[9] * a[4] + b[10] * a[8] + b[11] * a[12],
    b[8] * a[1] + b[9] * a[5] + b[10] * a[9] + b[11] * a[13],
    b[8] * a[2] + b[9] * a[6] + b[10] * a[10] + b[11] * a[14],
    b[8] * a[3] + b[9] * a[7] + b[10] * a[11] + b[11] * a[15],
    b[12] * a[0] + b[13] * a[4] + b[14] * a[8] + b[15] * a[12],
    b[12] * a[1] + b[13] * a[5] + b[14] * a[9] + b[15] * a[13],
    b[12] * a[2] + b[13] * a[6] + b[14] * a[10] + b[15] * a[14],
    b[12] * a[3] + b[13] * a[7] + b[14] * a[11] + b[15] * a[15],
  ];
};

export const invert4 = (a: number[]) => {
  let b00 = a[0] * a[5] - a[1] * a[4];
  let b01 = a[0] * a[6] - a[2] * a[4];
  let b02 = a[0] * a[7] - a[3] * a[4];
  let b03 = a[1] * a[6] - a[2] * a[5];
  let b04 = a[1] * a[7] - a[3] * a[5];
  let b05 = a[2] * a[7] - a[3] * a[6];
  let b06 = a[8] * a[13] - a[9] * a[12];
  let b07 = a[8] * a[14] - a[10] * a[12];
  let b08 = a[8] * a[15] - a[11] * a[12];
  let b09 = a[9] * a[14] - a[10] * a[13];
  let b10 = a[9] * a[15] - a[11] * a[13];
  let b11 = a[10] * a[15] - a[11] * a[14];
  let det =
    b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;
  if (!det) return null;
  return [
    (a[5] * b11 - a[6] * b10 + a[7] * b09) / det,
    (a[2] * b10 - a[1] * b11 - a[3] * b09) / det,
    (a[13] * b05 - a[14] * b04 + a[15] * b03) / det,
    (a[10] * b04 - a[9] * b05 - a[11] * b03) / det,
    (a[6] * b08 - a[4] * b11 - a[7] * b07) / det,
    (a[0] * b11 - a[2] * b08 + a[3] * b07) / det,
    (a[14] * b02 - a[12] * b05 - a[15] * b01) / det,
    (a[8] * b05 - a[10] * b02 + a[11] * b01) / det,
    (a[4] * b10 - a[5] * b08 + a[7] * b06) / det,
    (a[1] * b08 - a[0] * b10 - a[3] * b06) / det,
    (a[12] * b04 - a[13] * b02 + a[15] * b00) / det,
    (a[9] * b02 - a[8] * b04 - a[11] * b00) / det,
    (a[5] * b07 - a[4] * b09 - a[6] * b06) / det,
    (a[0] * b09 - a[1] * b07 + a[2] * b06) / det,
    (a[13] * b01 - a[12] * b03 - a[14] * b00) / det,
    (a[8] * b03 - a[9] * b01 + a[10] * b00) / det,
  ];
};

export const rotate4 = (
  a: number[],
  rad: number,
  x: number,
  y: number,
  z: number
) => {
  let len = Math.hypot(x, y, z);
  x /= len;
  y /= len;
  z /= len;
  let s = Math.sin(rad);
  let c = Math.cos(rad);
  let t = 1 - c;
  let b00 = x * x * t + c;
  let b01 = y * x * t + z * s;
  let b02 = z * x * t - y * s;
  let b10 = x * y * t - z * s;
  let b11 = y * y * t + c;
  let b12 = z * y * t + x * s;
  let b20 = x * z * t + y * s;
  let b21 = y * z * t - x * s;
  let b22 = z * z * t + c;
  return [
    a[0] * b00 + a[4] * b01 + a[8] * b02,
    a[1] * b00 + a[5] * b01 + a[9] * b02,
    a[2] * b00 + a[6] * b01 + a[10] * b02,
    a[3] * b00 + a[7] * b01 + a[11] * b02,
    a[0] * b10 + a[4] * b11 + a[8] * b12,
    a[1] * b10 + a[5] * b11 + a[9] * b12,
    a[2] * b10 + a[6] * b11 + a[10] * b12,
    a[3] * b10 + a[7] * b11 + a[11] * b12,
    a[0] * b20 + a[4] * b21 + a[8] * b22,
    a[1] * b20 + a[5] * b21 + a[9] * b22,
    a[2] * b20 + a[6] * b21 + a[10] * b22,
    a[3] * b20 + a[7] * b21 + a[11] * b22,
    ...a.slice(12, 16),
  ];
};

export const translate4 = (a: number[], x: number, y: number, z: number) => {
  return [
    ...a.slice(0, 12),
    a[0] * x + a[4] * y + a[8] * z + a[12],
    a[1] * x + a[5] * y + a[9] * z + a[13],
    a[2] * x + a[6] * y + a[10] * z + a[14],
    a[3] * x + a[7] * y + a[11] * z + a[15],
  ];
};

export const getViewMatrix = (camera: Camera) => {
  const R = camera.rotation.flat();
  const t = camera.position;
  const camToWorld = [
    [R[0], R[1], R[2], 0],
    [R[3], R[4], R[5], 0],
    [R[6], R[7], R[8], 0],
    [
      -t[0] * R[0] - t[1] * R[3] - t[2] * R[6],
      -t[0] * R[1] - t[1] * R[4] - t[2] * R[7],
      -t[0] * R[2] - t[1] * R[5] - t[2] * R[8],
      1,
    ],
  ].flat();
  return camToWorld;
};

export const createWorker = (self: Self) => {
  let buffer: ArrayBuffer;
  let vertexCount = 0;
  let viewProj: any;
  // 6*4 + 4 + 4 = 8*4
  // XYZ - Position (Float32)
  // XYZ - Scale (Float32)
  // RGBA - colors (uint8)
  // IJKL - quaternion/rot (uint8)
  const rowLength = 3 * 4 + 3 * 4 + 4 + 4;
  let lastProj: number[] = [];
  let depthIndex = new Uint32Array();

  const runSort = (viewProj: number[]) => {
    if (!buffer) return;

    const f_buffer = new Float32Array(buffer);
    const u_buffer = new Uint8Array(buffer);

    const covA = new Float32Array(3 * vertexCount);
    const covB = new Float32Array(3 * vertexCount);

    const center = new Float32Array(3 * vertexCount);
    const color = new Float32Array(4 * vertexCount);

    if (depthIndex.length === vertexCount) {
      let dot =
        lastProj[2] * viewProj[2] +
        lastProj[6] * viewProj[6] +
        lastProj[10] * viewProj[10];
      if (Math.abs(dot - 1) < 0.01) {
        return;
      }
    }

    let maxDepth = -Infinity;
    let minDepth = Infinity;
    let sizeList = new Int32Array(vertexCount);
    for (let i = 0; i < vertexCount; i++) {
      let depth =
        ((viewProj[2] * f_buffer[8 * i + 0] +
          viewProj[6] * f_buffer[8 * i + 1] +
          viewProj[10] * f_buffer[8 * i + 2]) *
          4096) |
        0;
      sizeList[i] = depth;
      if (depth > maxDepth) maxDepth = depth;
      if (depth < minDepth) minDepth = depth;
    }
    // console.time("sort");

    // This is a 16 bit single-pass counting sort
    let depthInv = (256 * 256) / (maxDepth - minDepth);
    let counts0 = new Uint32Array(256 * 256);
    for (let i = 0; i < vertexCount; i++) {
      sizeList[i] = ((sizeList[i] - minDepth) * depthInv) | 0;
      counts0[sizeList[i]]++;
    }
    let starts0 = new Uint32Array(256 * 256);
    for (let i = 1; i < 256 * 256; i++)
      starts0[i] = starts0[i - 1] + counts0[i - 1];
    depthIndex = new Uint32Array(vertexCount);
    for (let i = 0; i < vertexCount; i++)
      depthIndex[starts0[sizeList[i]]++] = i;

    lastProj = viewProj;
    // console.timeEnd("sort");
    for (let j = 0; j < vertexCount; j++) {
      const i = depthIndex[j];

      center[3 * j + 0] = f_buffer[8 * i + 0];
      center[3 * j + 1] = f_buffer[8 * i + 1];
      center[3 * j + 2] = f_buffer[8 * i + 2];

      color[4 * j + 0] = u_buffer[32 * i + 24 + 0] / 255;
      color[4 * j + 1] = u_buffer[32 * i + 24 + 1] / 255;
      color[4 * j + 2] = u_buffer[32 * i + 24 + 2] / 255;
      color[4 * j + 3] = u_buffer[32 * i + 24 + 3] / 255;

      let scale = [
        f_buffer[8 * i + 3 + 0],
        f_buffer[8 * i + 3 + 1],
        f_buffer[8 * i + 3 + 2],
      ];
      let rot = [
        (u_buffer[32 * i + 28 + 0] - 128) / 128,
        (u_buffer[32 * i + 28 + 1] - 128) / 128,
        (u_buffer[32 * i + 28 + 2] - 128) / 128,
        (u_buffer[32 * i + 28 + 3] - 128) / 128,
      ];

      const R = [
        1.0 - 2.0 * (rot[2] * rot[2] + rot[3] * rot[3]),
        2.0 * (rot[1] * rot[2] + rot[0] * rot[3]),
        2.0 * (rot[1] * rot[3] - rot[0] * rot[2]),

        2.0 * (rot[1] * rot[2] - rot[0] * rot[3]),
        1.0 - 2.0 * (rot[1] * rot[1] + rot[3] * rot[3]),
        2.0 * (rot[2] * rot[3] + rot[0] * rot[1]),

        2.0 * (rot[1] * rot[3] + rot[0] * rot[2]),
        2.0 * (rot[2] * rot[3] - rot[0] * rot[1]),
        1.0 - 2.0 * (rot[1] * rot[1] + rot[2] * rot[2]),
      ];

      // Compute the matrix product of S and R (M = S * R)
      const M = [
        scale[0] * R[0],
        scale[0] * R[1],
        scale[0] * R[2],
        scale[1] * R[3],
        scale[1] * R[4],
        scale[1] * R[5],
        scale[2] * R[6],
        scale[2] * R[7],
        scale[2] * R[8],
      ];

      covA[3 * j + 0] = M[0] * M[0] + M[3] * M[3] + M[6] * M[6];
      covA[3 * j + 1] = M[0] * M[1] + M[3] * M[4] + M[6] * M[7];
      covA[3 * j + 2] = M[0] * M[2] + M[3] * M[5] + M[6] * M[8];
      covB[3 * j + 0] = M[1] * M[1] + M[4] * M[4] + M[7] * M[7];
      covB[3 * j + 1] = M[1] * M[2] + M[4] * M[5] + M[7] * M[8];
      covB[3 * j + 2] = M[2] * M[2] + M[5] * M[5] + M[8] * M[8];
    }

    self.postMessage({ covA, center, color, covB, viewProj }, [
      covA.buffer,
      center.buffer,
      color.buffer,
      covB.buffer,
    ]);

    // console.timeEnd("sort");
  };

  const processPlyBuffer = (inputBuffer: ArrayBuffer) => {
    const ubuf = new Uint8Array(inputBuffer);
    // 10KB ought to be enough for a header...
    const header = new TextDecoder().decode(ubuf.slice(0, 1024 * 10));
    const header_end = "end_header\n";
    const header_end_index = header.indexOf(header_end);
    if (header_end_index < 0)
      throw new Error("Unable to read .ply file header");
    const vertexCount = parseInt(
      /element vertex (\d+)\n/.exec(header)?.[1] || ""
    );
    console.log("Vertex Count", vertexCount);
    let row_offset = 0;
    let offsets: { [x: string]: number } = {};
    let types: { [x: string]: string } = {};

    const TYPE_MAP = {
      double: "getFloat64",
      int: "getInt32",
      uint: "getUint32",
      float: "getFloat32",
      short: "getInt16",
      ushort: "getUint16",
      uchar: "getUint8",
    };
    for (let prop of header
      .slice(0, header_end_index)
      .split("\n")
      .filter((k) => k.startsWith("property "))) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, type, name] = prop.split(" ");
      const arrayType = TYPE_MAP[type as keyof typeof TYPE_MAP] || "getInt8";
      types[name] = arrayType;
      offsets[name] = row_offset;
      row_offset += parseInt(arrayType.replace(/[^\d]/g, "")) / 8;
    }
    console.log("Bytes per row", row_offset, types, offsets);

    let dataView = new DataView(
      inputBuffer,
      header_end_index + header_end.length
    );
    let row = 0;
    const attrs = new Proxy<{ [x: string]: number }>(
      {},
      {
        get(_, prop: string) {
          if (!types[prop]) throw new Error(prop + " not found");
          const type = types[prop] as keyof typeof dataView;
          //@ts-ignore
          return dataView[type](row * row_offset + offsets[prop], true);
        },
      }
    );

    console.time("calculate importance");
    let sizeList = new Float32Array(vertexCount);
    let sizeIndex = new Uint32Array(vertexCount);
    for (row = 0; row < vertexCount; row++) {
      sizeIndex[row] = row;
      if (!types["scale_0"]) continue;
      const size =
        Math.exp(attrs.scale_0) *
        Math.exp(attrs.scale_1) *
        Math.exp(attrs.scale_2);
      const opacity = 1 / (1 + Math.exp(-attrs.opacity));
      sizeList[row] = size * opacity;
    }
    console.timeEnd("calculate importance");

    console.time("sort");
    sizeIndex.sort((b, a) => sizeList[a] - sizeList[b]);
    console.timeEnd("sort");

    // 6*4 + 4 + 4 = 8*4
    // XYZ - Position (Float32)
    // XYZ - Scale (Float32)
    // RGBA - colors (uint8)
    // IJKL - quaternion/rot (uint8)
    const rowLength = 3 * 4 + 3 * 4 + 4 + 4;
    const buffer = new ArrayBuffer(rowLength * vertexCount);

    console.time("build buffer");
    for (let j = 0; j < vertexCount; j++) {
      row = sizeIndex[j];

      const position = new Float32Array(buffer, j * rowLength, 3);
      const scales = new Float32Array(buffer, j * rowLength + 4 * 3, 3);
      const rgba = new Uint8ClampedArray(
        buffer,
        j * rowLength + 4 * 3 + 4 * 3,
        4
      );
      const rot = new Uint8ClampedArray(
        buffer,
        j * rowLength + 4 * 3 + 4 * 3 + 4,
        4
      );

      if (types["scale_0"]) {
        const qlen = Math.sqrt(
          attrs.rot_0 ** 2 +
            attrs.rot_1 ** 2 +
            attrs.rot_2 ** 2 +
            attrs.rot_3 ** 2
        );

        rot[0] = (attrs.rot_0 / qlen) * 128 + 128;
        rot[1] = (attrs.rot_1 / qlen) * 128 + 128;
        rot[2] = (attrs.rot_2 / qlen) * 128 + 128;
        rot[3] = (attrs.rot_3 / qlen) * 128 + 128;

        scales[0] = Math.exp(attrs.scale_0);
        scales[1] = Math.exp(attrs.scale_1);
        scales[2] = Math.exp(attrs.scale_2);
      } else {
        scales[0] = 0.01;
        scales[1] = 0.01;
        scales[2] = 0.01;

        rot[0] = 255;
        rot[1] = 0;
        rot[2] = 0;
        rot[3] = 0;
      }

      position[0] = attrs.x;
      position[1] = attrs.y;
      position[2] = attrs.z;

      if (types["f_dc_0"]) {
        const SH_C0 = 0.28209479177387814;
        rgba[0] = (0.5 + SH_C0 * attrs.f_dc_0) * 255;
        rgba[1] = (0.5 + SH_C0 * attrs.f_dc_1) * 255;
        rgba[2] = (0.5 + SH_C0 * attrs.f_dc_2) * 255;
      } else {
        rgba[0] = attrs.red;
        rgba[1] = attrs.green;
        rgba[2] = attrs.blue;
      }
      if (types["opacity"]) {
        rgba[3] = (1 / (1 + Math.exp(-attrs.opacity))) * 255;
      } else {
        rgba[3] = 255;
      }
    }
    console.timeEnd("build buffer");
    return buffer;
  };

  const throttledSort = () => {
    if (!sortRunning) {
      sortRunning = true;
      let lastView = viewProj;
      runSort(lastView);
      setTimeout(() => {
        sortRunning = false;
        if (lastView !== viewProj) {
          throttledSort();
        }
      }, 0);
    }
  };

  let sortRunning: boolean;
  self.onmessage = (e) => {
    if (e.data.ply) {
      vertexCount = 0;
      runSort(viewProj);
      buffer = processPlyBuffer(e.data.ply);
      vertexCount = Math.floor(buffer.byteLength / rowLength);
      postMessage({ buffer: buffer });
    } else if (e.data.buffer) {
      buffer = e.data.buffer;
      vertexCount = e.data.vertexCount;
    } else if (e.data.vertexCount) {
      vertexCount = e.data.vertexCount;
    } else if (e.data.view) {
      viewProj = e.data.view;
      throttledSort();
    }
  };
};

export function createSortWorker(self: {
  postMessage: (
    arg0: { color: Float32Array; centerCov: Float32Array },
    arg1: ArrayBufferLike[]
  ) => void;
  onmessage: (e: any) => void;
}) {
  let splatBuffer: Iterable<number>;
  let precomputedCovariance: Iterable<number>;
  let precomputedColor: Iterable<number>;
  let vertexCount = 0;
  let viewProj: any;
  let depthMix = new BigInt64Array();
  let lastProj: number[] = [];

  let rowSizeFloats = 0;

  const runSort = (viewProj: number[]) => {
    if (!splatBuffer) return;

    const splatArray = new Float32Array(splatBuffer);
    const pCovarianceArray = new Float32Array(precomputedCovariance);
    const pColorArray = new Float32Array(precomputedColor);
    const color = new Float32Array(4 * vertexCount);
    const centerCov = new Float32Array(9 * vertexCount);

    if (depthMix.length !== vertexCount) {
      depthMix = new BigInt64Array(vertexCount);
      const indexMix = new Uint32Array(depthMix.buffer);
      for (let j = 0; j < vertexCount; j++) {
        indexMix[2 * j] = j;
      }
    } else {
      let dot =
        lastProj[2] * viewProj[2] +
        lastProj[6] * viewProj[6] +
        lastProj[10] * viewProj[10];
      if (Math.abs(dot - 1) < 0.01) {
        return;
      }
    }

    const floatMix = new Float32Array(depthMix.buffer);
    const indexMix = new Uint32Array(depthMix.buffer);

    for (let j = 0; j < vertexCount; j++) {
      let i = indexMix[2 * j];
      const splatArrayBase = rowSizeFloats * i;
      floatMix[2 * j + 1] =
        10000 +
        viewProj[2] * splatArray[splatArrayBase] +
        viewProj[6] * splatArray[splatArrayBase + 1] +
        viewProj[10] * splatArray[splatArrayBase + 2];
    }

    lastProj = viewProj;

    depthMix.sort();

    for (let j = 0; j < vertexCount; j++) {
      const i = indexMix[2 * j];

      const centerCovBase = 9 * j;
      const pCovarianceBase = 6 * i;
      const colorBase = 4 * j;
      const pcColorBase = 4 * i;
      const splatArrayBase = rowSizeFloats * i;

      centerCov[centerCovBase] = splatArray[splatArrayBase];
      centerCov[centerCovBase + 1] = splatArray[splatArrayBase + 1];
      centerCov[centerCovBase + 2] = splatArray[splatArrayBase + 2];

      color[colorBase] = pColorArray[pcColorBase];
      color[colorBase + 1] = pColorArray[pcColorBase + 1];
      color[colorBase + 2] = pColorArray[pcColorBase + 2];
      color[colorBase + 3] = pColorArray[pcColorBase + 3];

      centerCov[centerCovBase + 3] = pCovarianceArray[pCovarianceBase];
      centerCov[centerCovBase + 4] = pCovarianceArray[pCovarianceBase + 1];
      centerCov[centerCovBase + 5] = pCovarianceArray[pCovarianceBase + 2];
      centerCov[centerCovBase + 6] = pCovarianceArray[pCovarianceBase + 3];
      centerCov[centerCovBase + 7] = pCovarianceArray[pCovarianceBase + 4];
      centerCov[centerCovBase + 8] = pCovarianceArray[pCovarianceBase + 5];
    }

    self.postMessage({ color, centerCov }, [color.buffer, centerCov.buffer]);
  };

  const throttledSort = () => {
    if (!sortRunning) {
      sortRunning = true;
      let lastView = viewProj;
      runSort(lastView);
      setTimeout(() => {
        sortRunning = false;
        if (lastView !== viewProj) {
          throttledSort();
        }
      }, 0);
    }
  };

  let sortRunning: boolean;
  self.onmessage = (e) => {
    if (e.data.bufferUpdate) {
      rowSizeFloats = e.data.bufferUpdate.rowSizeFloats;
      // rowSizeBytes = e.data.bufferUpdate.rowSizeBytes;
      splatBuffer = e.data.bufferUpdate.splatBuffer;
      precomputedCovariance = e.data.bufferUpdate.precomputedCovariance;
      precomputedColor = e.data.bufferUpdate.precomputedColor;
      vertexCount = e.data.bufferUpdate.vertexCount;
    } else if (e.data.sort) {
      viewProj = e.data.sort.view;
      throttledSort();
    }
  };
}
