import { FC } from "react";

import { Cards } from "../components/Cards";
import { PageWrapper } from "../components/PageWrapper";
import { SidePanel } from "../components/SidePanel";

import { useLazyModels } from "../utils/hooks";

export const AllModelsPage: FC = () => {
  const showModels = useLazyModels("/all");

  return (
    <PageWrapper>
      <SidePanel />
      <Cards models={showModels} />
    </PageWrapper>
  );
};
