import { FC } from "react";
import styled from "styled-components/macro";
import { Body1, H1, H2, Button } from "@salutejs/plasma-b2c";

import sceneFrame from "../../assets/scene-frame-line.png";

import { Icon } from "../Icon";
import { PropsAnimate } from "./types";
import { transition } from "./constants";
import {
  generateStyleAnimate,
  pxToPercentHeight,
  pxToPercentWidth,
} from "./utils";

const positionsTextBlockMain = [
  //0
  {
    width: 564,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //1
  {
    width: 564,
    left: 64,
    top: 36,
    opacity: 0,
  },
];

const positionsBackgroundMain = [
  //0
  {
    width: 564,
    height: 159,
    left: 480,
    top: 170,
    opacity: 0,
  },

  //1
  {
    width: 1123,
    height: 530,
    left: 38,
    top: 120,
    opacity: 1,
  },
];

type PositionTextBlockMain = typeof positionsTextBlockMain[0];
type PositionBackgroundMain = typeof positionsBackgroundMain[0];

const TextBlockMain = styled.div<PropsAnimate>`
  position: absolute;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockMain>(
      positionsTextBlockMain[currentAnimate] ||
        positionsTextBlockMain[positionsTextBlockMain.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${H1} {
    margin-bottom: ${pxToPercentHeight(20)};
    font-size: 64px;
    line-height: 68px;

    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.37),
        rgba(255, 255, 255, 0.37)
      ),
      linear-gradient(266.7deg, #22ded3 2.89%, #7626f9 102.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  ${Body1} {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  > ${Body1} {
    margin-bottom: ${pxToPercentHeight(87)};
  }

  > div:last-child {
    display: flex;
    > div {
      width: ${pxToPercentWidth(190)};
      margin-right: ${pxToPercentWidth(40)};

      button {
        padding: 0;
      }
    }
  }
`;

const BackgroundMain = styled.div<PropsAnimate>`
  position: absolute;
  aspect-ratio: 1123/530;

  ${transition}

  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionBackgroundMain>(
      positionsBackgroundMain[currentAnimate] ||
        positionsBackgroundMain[positionsBackgroundMain.length - 1]
    )};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const MainBlock: FC<PropsAnimate> = ({ currentAnimate }) => {
  if (currentAnimate > positionsBackgroundMain.length) {
    return null;
  }

  return (
    <>
      <TextBlockMain currentAnimate={currentAnimate}>
        <H1>
          3D - объекты <br />
          и сцены из видео
        </H1>

        <Body1>Алгоритмическая обработка видео и превращение в модель</Body1>

        <div>
          <div>
            <H2>Раз</H2>
            <Body1>Сними видео вокруг объекта</Body1>
            <Button
              view="clear"
              contentLeft={<Icon name="arrow_diag_24_line" />}
              text={<Body1>Как снимать</Body1>}
            />
          </div>

          <div>
            <H2>Два</H2>
            <Body1>Отправь видео боту Telegram</Body1>
            <Button
              view="clear"
              contentLeft={<Icon name="arrow_diag_24_line" />}
              text={<Body1>@BotName</Body1>}
            />
          </div>
        </div>
      </TextBlockMain>

      <BackgroundMain currentAnimate={currentAnimate}>
        <img src={sceneFrame} alt="" />
      </BackgroundMain>
    </>
  );
};
