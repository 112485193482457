import { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { Display1 } from "@salutejs/plasma-core";
import { Image } from "@salutejs/plasma-b2c";

import { init, onWindowResize } from "../../utils/MobileNerf";
import { useStateContext } from "../../utils/context";
import { IModel, Paths } from "../../utils/types";
import { unpack } from "../../utils/unpack";

import { SettingsPanel } from "../SettingsPanel";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ProgressiveImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    filter: blur(10px);
    clip-path: inset(0);
    object-fit: contain;
  }
`;

const ModelWrapper = styled.div`
  width: 100%;
  height: 100%;

  > canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const HelpPositionWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, 0);
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.15);
`;

const Progress = styled(Display1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface Props {
  model?: IModel;
}

export const Model: FC<Props> = ({ model }) => {
  const { isFullScreenModel } = useStateContext();

  const [paths, setPaths] = useState<Paths>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [extractedFile, setExtractedFile] = useState<boolean>(false);

  const container = useRef<HTMLDivElement>(null);
  const containerHelpPosition = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const configScene = useRef<{ [key: string]: any }>({});

  const toggleAutoRotate = useCallback(() => {
    configScene.current.autoRotate = !configScene.current.autoRotate;
  }, []);

  const getPaths = async (abortController: AbortController) => {
    if (!model) {
      return;
    }

    try {
      const pathsData = await unpack({
        url: model.path_obj_pre_phone,
        setProgress,
        setExtractedFile,
        abortController,
      });

      setPaths(pathsData);

      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (model) {
      getPaths(abortController);
    }

    return () => {
      abortController.abort();
      if (model && container.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const div = container.current;
        while (div.firstChild) {
          div.removeChild(div.firstChild);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (!paths) {
      return;
    }

    const config: any = init({
      paths,
      container: container.current,
      containerHelpPosition: containerHelpPosition.current,
      wrapper: wrapper.current,
    });
    config.isStopRender = false;
    configScene.current = config;
    return () => {
      config.isStopRender = true;
    };
  }, [paths]);

  useEffect(() => {
    if (!isLoading) {
      onWindowResize(0, 0);
    }
  }, [isFullScreenModel, isLoading]);

  return (
    <Wrapper ref={wrapper}>
      {(isLoading || extractedFile) && model && (
        <ProgressiveImage src={`https://cdn.sxr.su/${model?.path_preview}`} />
      )}

      {progress !== 100 && <Progress>{progress}%</Progress>}

      {!isLoading && <SettingsPanel toggleAutoRotate={toggleAutoRotate} />}

      <ModelWrapper id={model?.uid} ref={container} />

      <HelpPositionWrapper ref={containerHelpPosition} />
    </Wrapper>
  );
};
