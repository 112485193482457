import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";

import { BodyS, Button } from "@salutejs/plasma-b2c";
import { DropdownNode } from "@salutejs/plasma-web/components/Dropdown/Dropdown.types";

import { axiosScene } from "../utils/axios";
import { useModal, useWindowSize } from "../utils/hooks";
import { IModel, ModelPlyI } from "../utils/types";

import { InfoPanel } from "../components/InfoPanel";
import { Wrapper } from "../components/PageWrapper";
import { Header } from "../components/Header";
import { Icon } from "../components/Icon";
import { WidgetModal } from "../components/WidgetModal";
import { ModelGlb } from "../components/ModelGlb";

import { downloadModel } from "../utils/libs";
import { useStateContext } from "../utils/context";
import { SIZES } from "../utils/breakpoints";

const ITEM_DROPDOWN_VALUE = {
  copy: "copy",
  download: "download",
};

const StyledPageWrapper = styled(Wrapper)<{ isFullScreenModel: boolean }>`
  display: flex;
  padding-top: 0;
  grid-template: none;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  > div {
    &:first-child {
      margin-top: ${({ isFullScreenModel }) =>
        isFullScreenModel ? "0" : "28px"};
      position: relative;
    }
  }

  @media (max-width: ${SIZES.sm}px) {
    flex-direction: column;

    > div {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const ModelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${SIZES.sm}px) {
    height: 100%;
  }
`;

const ArrowSlide = styled(Button)<{ isRight?: boolean }>`
  position: absolute;
  z-index: 1;

  ${({ isRight }) => (isRight ? "right:0" : "left:0")};

  &:hover {
    path {
      stroke-opacity: 1;
    }
  }
`;

const Close = styled(Button)`
  position: absolute;
  right: 0;
  z-index: 1;
  width: auto;
  top: 24px;
`;

export const ModelPageGlb: FC = () => {
  const { models, isFullScreenModel } = useStateContext();

  const [isOpenWidgetModal, openWidgetModal, closeWidgetModal] = useModal();

  const [model, setModel] = useState<IModel>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [error, setError] = useState<string>("");

  const { uid } = useParams<{ uid: string }>();
  const [params] = useSearchParams();

  const navigate = useNavigate();

  const { isMobile } = useWindowSize();

  const { searchQuery, back } = useMemo(
    () => ({ searchQuery: params.get("search"), back: params.get("back") }),
    [params]
  );

  const { beforeUid, afterUid } = useMemo(() => {
    const indexModel = models.findIndex((model) => model.uid === uid);
    const beforeUid = models[indexModel - 1]?.uid;
    const afterUid = models[indexModel + 1]?.uid;

    return { beforeUid, afterUid };
  }, [models, uid]);

  const handlerClickArrow = (uid?: string) => {
    if (!uid) {
      return null;
    }

    const options = {
      searchQuery,
      back,
    };

    const filteredOptions = Object.entries(options).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: String(value) }),
      {}
    );

    const params = new URLSearchParams(filteredOptions);

    navigate(`/models/${uid}?${params}`);
  };

  const onItemSelect = useCallback(
    (item: DropdownNode) => {
      switch (item.value) {
        case ITEM_DROPDOWN_VALUE.copy:
          openWidgetModal();
          break;

        case ITEM_DROPDOWN_VALUE.download:
          if (model) {
            downloadModel(model.uid, model.path_glb || "");
          }
          break;
      }
    },
    [model, openWidgetModal]
  );

  const dropdownItems = useMemo<DropdownNode[]>(() => {
    return [
      {
        value: ITEM_DROPDOWN_VALUE.download,
        label: "Скачать",
        contentLeft: <Icon name="cloud_download_24" />,
      },
    ];
  }, []);

  const getModel = async (uid?: string) => {
    setIsLoading(true);
    if (!uid) {
      setIsLoading(false);
      setError("error");
      return;
    }

    try {
      const { data } = (await axiosScene.get(`/model/${uid}`)) as {
        data: ModelPlyI[];
      };

      setIsLoading(false);

      if (!data?.[0]) {
        return;
      }

      setModel(data[0]);
    } catch (error) {
      setError("error");
    }
  };

  useEffect(() => {
    getModel(uid);
  }, [uid]);

  return (
    <StyledPageWrapper isFullScreenModel={isFullScreenModel}>
      {!isFullScreenModel && (
        <div>
          <Header
            closeSrc={isMobile && back ? String(back) : undefined}
            dropdownItems={dropdownItems}
            onItemSelect={onItemSelect}
          />
          <WidgetModal
            uid={model?.uid}
            isOpenWidgetModal={isOpenWidgetModal}
            closeWidgetModal={closeWidgetModal}
          />
          <InfoPanel model={model} isGlb />
        </div>
      )}

      <ModelWrapper>
        {!isMobile && (
          <Close
            view="secondary"
            size="s"
            text={<BodyS>Закрыть</BodyS>}
            contentRight={<Icon name="close_24_line" />}
            onClick={() => navigate(String(back))}
          />
        )}

        {!isMobile && (
          <ArrowSlide
            view="clear"
            text={beforeUid ? <Icon name="arrow_slide_left" /> : null}
            onClick={() => {
              handlerClickArrow(beforeUid);
            }}
          />
        )}

        <ModelGlb model={model} isLoading={isLoading} />

        {!isMobile && (
          <ArrowSlide
            isRight
            view="clear"
            text={afterUid ? <Icon name="arrow_slide_right" /> : null}
            onClick={() => {
              handlerClickArrow(afterUid);
            }}
          />
        )}
      </ModelWrapper>
    </StyledPageWrapper>
  );
};
