/* eslint-disable*/
/* eslint-disable*/
import { Html, OrbitControls, useGLTF, useProgress } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import { FC, Suspense, useMemo } from "react";
import { gbuffer_frag, gbuffer_vert } from "../utils/shaders";
import * as THREE from "three";
import { TextureLoader } from "three";

const Fragment = () => {
  const { nodes, materials } = useGLTF("/obj_panda_phone/mesh.glb");

  const obj_name = "/obj_panda";
  const i = 0;
    const [tex0, tex1] = useLoader(TextureLoader, [
      obj_name + "_phone/shape" + i.toFixed(0) + ".png" + "feat0.png",
      obj_name + "_phone/shape" + i.toFixed(0) + ".png" + "feat1.png",
    ]);

//   const tex0 = new THREE.TextureLoader().load(
//     obj_name + "_phone/shape" + i.toFixed(0) + ".png" + "feat0.png",
//     function (map) {
//       map.flipY = false;
//       //   render();
//     }
//   );
//   tex0.magFilter = THREE.NearestFilter;
//   console.log(tex0);

//   const tex1 = new THREE.TextureLoader().load(
//     obj_name + "_phone/shape" + i.toFixed(0) + ".png" + "feat1.png",
//     function (map) {
//       map.flipY = false;
//       //   render();
//     }
//   );
//   tex1.minFilter = THREE.NearestFilter;

  const uniform = useMemo(
    () => ({
      tDiffuse0: { value: tex0 },
      tDiffuse1: { value: tex1 },
    }),
    []
  );

  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        // @ts-ignore
        geometry={nodes.Node.geometry}
        // material={materials["default"]}
      >
        <rawShaderMaterial
          vertexShader={gbuffer_vert}
          fragmentShader={gbuffer_frag}
          side={THREE.DoubleSide}
          uniforms={uniform}
          glslVersion={THREE.GLSL3}
        />


        {/* <meshStandardMaterial
          vertexColors
          map={colorMap}
          normalMap={normalMap}
        /> */}
      </mesh>
    </group>
  );
};

export const Scene: FC = () => {
  function Loader() {
    const { progress } = useProgress();
    return <Html center>{progress} % loaded</Html>;
  }

  return (
    <Canvas camera={{ position: [0.0, 0.0, 5.0] }} style={{ height: 600 }}>
      <Suspense fallback={<Loader />}>
        <ambientLight intensity={1} />
        <directionalLight />
        <Fragment />
        <OrbitControls />
      </Suspense>
    </Canvas>
  );
};
