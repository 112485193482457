import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { BodyL, BodyS, BodyXXS } from "@salutejs/plasma-b2c";
import { surfaceSolid03 } from "@salutejs/plasma-tokens-b2c";

import { Icon, IconNames } from "../Icon";

const Background = styled.div`
  position: absolute;
  width: 0;
  height: 100%;
  background: #3f81fd;
  z-index: 0;
  transition: width 0.12s linear;
`;

const Wrapper = styled.div<{ isCard?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  max-width: 100%;
  width: fit-content;
  height: 24px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  position: relative;
  background: ${({ isCard }) => (isCard ? `${surfaceSolid03}` : "initial")};

  > div {
    z-index: 1;
  }

  > span {
    &:last-child {
      visibility: hidden;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  &:hover {
    ${Background} {
      width: 100%;
    }

    > span {
      &:last-child {
        visibility: unset;
      }
    }
  }
`;

const Later = styled.div`
  overflow: hidden;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;

  background: linear-gradient(88.34deg, #05c1f5 0%, #3f81fd 100%);

  margin-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyXXS} {
    font-family: "SB Sans Display";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;

    background: linear-gradient(88.34deg, #4ad6fc 0%, #5993ff 100%);
    -webkit-background-clip: text;
  }
`;

const StyledBodyS = styled(BodyS)`
  margin: 0;
  margin-right: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  nickname: string;
  src?: string;
  isCard?: boolean;
  iconName?: IconNames;
};

export const Avatar: FC<Props> = ({ nickname, src, isCard, iconName }) => {
  const navigate = useNavigate();

  const onClickNickname = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (src) {
      navigate(`/${src}`);
    }
  };

  return (
    <Wrapper isCard={isCard}>
      <Background />

      <Later>
        <BodyXXS>{nickname.slice(0, 2).toUpperCase()}</BodyXXS>
      </Later>

      {isCard ? (
        <StyledBodyS onClick={onClickNickname}>@{nickname}</StyledBodyS>
      ) : (
        <BodyL onClick={onClickNickname}>@{nickname}</BodyL>
      )}

      {iconName && (
        <span>
          <Icon name={iconName || "arrow_right_24_line"} />
        </span>
      )}
    </Wrapper>
  );
};
