import { FC, useMemo } from "react";
import styled from "styled-components";

import { ElasticGrid } from "@salutejs/plasma-b2c";

import { SIZES } from "../../utils/breakpoints";
import { useStateContext } from "../../utils/context";
import { useWindowSize } from "../../utils/hooks";
import { IModel } from "../../utils/types";

import { Card, SkeletonCard } from "../Card";
import { ModelNotFound } from "../ModelNotFound";

const Wrapper = styled(ElasticGrid)`
  padding-top: 62px;
  margin-left: 40px;
  grid-area: model;

  @media (max-width: ${SIZES.sm}px) {
    margin-left: 0;
    padding-top: 0;
  }
`;

const WrapperNotFound = styled.div`
  width: 100%;
`;

interface Props {
  models?: IModel[];
}
export const Cards: FC<Props> = ({ models }) => {
  const { filteredModels, isLoading } = useStateContext();

  const { width } = useWindowSize();

  const gap = useMemo(() => (width < SIZES.lg ? 8 : 16), [width]);

  const resModels = useMemo(
    () => models || filteredModels,
    [models, filteredModels]
  );

  const listSkeleton = useMemo(() => {
    return Array.from({ length: (width - 400) / 300 }).map((_, i) => i);
  }, [width]);

  if (resModels.length === 0 && !isLoading) {
    return (
      <WrapperNotFound>
        <ModelNotFound />
      </WrapperNotFound>
    );
  }

  return (
    <Wrapper minColWidth={width < SIZES.lg ? 160 : 230} gapX={gap} gapY={gap}>
      {resModels.map((model) => (
        <Card key={model.uid} model={model} />
      ))}

      {isLoading && listSkeleton.map((key) => <SkeletonCard key={key} />)}
    </Wrapper>
  );
};
