import { Paths, UntarFile } from "./types";

export const handlePaths = (files: UntarFile[] | File[]): Paths => {
  const pathUrls = {
    mlpJson: "",
    shapeObj: "",
    pngfeat0: "",
    pngfeat1: "",
    normalsfile: "",
    mesh: "",
  };
  files.forEach((file) => {
    const blob =
      file instanceof File ? URL.createObjectURL(file) : file.getBlobUrl();

    if (file.name.includes("mlp.json")) {
      pathUrls.mlpJson = blob;
    }

    if (file.name.includes("shape0.obj")) {
      pathUrls.shapeObj = blob;
    }

    if (file.name.includes("shape0.pngfeat0.png")) {
      pathUrls.pngfeat0 = blob;
    }

    if (file.name.includes("shape0.pngfeat1.png")) {
      pathUrls.pngfeat1 = blob;
    }

    if (file.name.includes("normals_map.png")) {
      pathUrls.normalsfile = blob;
    }

    if (file.name.includes(".glb")) {
      pathUrls.mesh = blob;
    }
  });

  return pathUrls;
};
