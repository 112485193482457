import { FC } from "react";
import styled from "styled-components/macro";

import { Icon } from "../Icon";

import { PropsAnimate } from "./types";
import {
  generateStyleAnimate,
  pxToPercentHeight,
  pxToPercentWidth,
} from "./utils";

const positions = [
  //0
  {
    fill: "white",
  },

  //1
  {
    fill: "white",
  },

  //2
  {
    fill: "white",
  },

  //3
  {
    fill: "black",
  },

  //4
  {
    fill: "black",
  },

  //5
  {
    fill: "white",
  },
];

type Position = typeof positions[0];

const Wrapper = styled.div<PropsAnimate>`
  position: absolute;
  left: ${pxToPercentWidth(64)};
  top: ${pxToPercentHeight(24)};
  z-index: 10;

  > div svg {
    width: 100%;
    height: 100%;

    ${({ currentAnimate }) =>
      generateStyleAnimate<Position>(
        positions[currentAnimate] || positions[positions.length - 1]
      )};
  }
`;

export const Logo: FC<PropsAnimate> = ({ currentAnimate }) => {
  return (
    <Wrapper currentAnimate={currentAnimate}>
      <Icon name="brand_logo" />
    </Wrapper>
  );
};
