import { FC } from "react";
import styled from "styled-components/macro";

import { H1 } from "@salutejs/plasma-b2c";

import { SIZES } from "../../utils/breakpoints";

import { Icon } from "../Icon";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledH1 = styled(H1)`
  position: absolute;
  z-index: 1;
  top: 49%;
  left: 35%;
  transform: translate(-50%, -50%);
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${SIZES.sm}px) {
    position: initial;
    transform: none;
  }
`;

type Props = {};

export const ModelNotFound: FC<Props> = () => {
  return (
    <Wrapper>
      <StyledIcon name="union" />
      <StyledH1>
        Модели <br />
        не найдены
      </StyledH1>
    </Wrapper>
  );
};
