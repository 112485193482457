import { FC } from "react";
import styled from "styled-components/macro";

import { BodyL, BodyS, Button, H2, TextSkeleton } from "@salutejs/plasma-b2c";
import { tertiary } from "@salutejs/plasma-tokens-b2c";

import { SIZES } from "../../utils/breakpoints";
import { useWindowSize } from "../../utils/hooks";
import { IModel } from "../../utils/types";
import { downloadModel } from "../../utils/libs";

import { Avatar } from "../Avatar";
import { Icon } from "../Icon";

const showDownloadModel = process.env.REACT_APP_SHOW_DOWNLOAD_MODEL;

const Wrapper = styled.div`
  color: white;
  min-width: 320px;
  max-width: 320px;
  margin-top: 67px;
  position: relative;

  grid-area: panel;

  @media (max-width: ${SIZES.xl}px) {
    min-width: 256px;
    max-width: 256px;
  }

  @media (max-width: ${SIZES.lg}px) {
    min-width: 240px;
    max-width: 240px;
  }

  @media (max-width: ${SIZES.sm}px) {
    min-width: 100%;
    max-width: 100%;
    margin-top: 0;
    position: absolute;
    background: linear-gradient(180deg, #000000 72.28%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateAdd = styled(BodyS)`
  color: ${tertiary};
  margin: 8px 0 20px;
`;

const ButtonIcon = styled(Button)`
  width: 100%;
  justify-content: flex-start;

  padding: 0;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${tertiary};
  margin: 32px 0 20px;
`;

type Props = {
  openShadersModal?: () => void;
  openWidgetModal?: () => void;
  model?: IModel;
  isGlb?: boolean;
};

export const InfoPanel: FC<Props> = ({
  model,
  openShadersModal,
  openWidgetModal,
  isGlb,
}) => {
  const { isMobile } = useWindowSize();

  const formatDate = (date: string): string =>
    new Date(date).toLocaleDateString(undefined, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

  if (!model) {
    return (
      <Wrapper>
        <TextSkeleton lines={20} size="body1" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <H2>{model.description}</H2>

      <DateAdd>Добавлено {formatDate(model.date)}</DateAdd>

      <Avatar
        nickname={model.nickname}
        src={model.nickname}
        iconName="arrow_right_24_line"
      />

      {!isMobile && (
        <>
          <Line />

          <ButtonsWrapper>
            {openWidgetModal && (
              <ButtonIcon
                view="clear"
                text={<BodyL>Копировать виджет</BodyL>}
                contentLeft={<Icon name="copy_line_24" />}
                onClick={openWidgetModal}
              />
            )}

            {showDownloadModel && (
              <ButtonIcon
                view="clear"
                text={<BodyL>Скачать</BodyL>}
                contentLeft={<Icon name="cloud_download_24" />}
                onClick={() =>
                  downloadModel(
                    model.uid,
                    isGlb ? model.path_glb || "" : model.path_obj_pre_phone
                  )
                }
              />
            )}

            {openShadersModal && (
              <button onClick={openShadersModal}>shaders</button>
            )}
          </ButtonsWrapper>

          <DateAdd>
            Дополнительная информация, например дата размещения и размер модели
          </DateAdd>
        </>
      )}
    </Wrapper>
  );
};
