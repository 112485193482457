import { Dispatch } from "react";
import pako from "pako";
//@ts-ignore
import untar from "js-untar";

import { axiosCdn } from "./axios";
import { Paths, UntarFile } from "./types";
import { handlePaths } from "./pathHandler";

export const unpack = async ({
  url,
  setProgress,
  setExtractedFile,
  abortController,
}: {
  url: string;
  setProgress: Dispatch<React.SetStateAction<number>>;
  setExtractedFile: Dispatch<React.SetStateAction<boolean>>;
  abortController?: AbortController;
}): Promise<Paths | undefined> => {
  setExtractedFile(true);

  const { data } = await axiosCdn({
    url: url,
    method: "GET",
    responseType: "arraybuffer",
    signal: abortController?.signal,
    onDownloadProgress: (progressEvent) => {
      if (progressEvent.progress) {
        setProgress(Math.round(progressEvent.progress * 100));
      }
    },
  });

  const binData = new Uint8Array(data);

  const arrBuffer = pako.inflate(binData).buffer;

  try {
    const files = (await untar(arrBuffer)) as UntarFile[];
    const filesUrls = handlePaths(files);

    setExtractedFile(false);
    return filesUrls;
  } catch (error) {
    console.log("---error", error);
  }

};
