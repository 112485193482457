import { ReactComponent as CloudDownload24 } from "../../assets/icons/Cloud-Download-24.svg";
import { ReactComponent as CopyLine24 } from "../../assets/icons/Copy-24-line.svg";
import { ReactComponent as Cube24Logo } from "../../assets/icons/Cube-24-logo.svg";
import { ReactComponent as Close24line } from "../../assets/icons/Close-24-line.svg";
import { ReactComponent as ArrowSlideLeft } from "../../assets/icons/Arrow-slide-left.svg";
import { ReactComponent as ArrowSlideRight } from "../../assets/icons/Arrow-slide-right.svg";
import { ReactComponent as ArrowRight24line } from "../../assets/icons/Arrow-Right-24-line.svg";
import { ReactComponent as ArrowLeft24line } from "../../assets/icons/Arrow-Left-24-line.svg";
import { ReactComponent as DisclosureUp16line } from "../../assets/icons/Disclosure-Up-16-line.svg";
import { ReactComponent as DisclosureDown16line } from "../../assets/icons/Disclosure-Down-16-line.svg";

import { ReactComponent as Cube24 } from "../../assets/icons/Cube-24.svg";
import { ReactComponent as FullScreenSquare24line } from "../../assets/icons/Fullscreen-Square-24-line.svg";
import { ReactComponent as Play24line } from "../../assets/icons/Play-24-line.svg";
import { ReactComponent as Play } from "../../assets/icons/play.svg";
import { ReactComponent as ThreeDots24 } from "../../assets/icons/Three-dots-24.svg";
import { ReactComponent as Search24 } from "../../assets/icons/Search-24.svg";
import { ReactComponent as Search24Fill } from "../../assets/icons/Search-24-fill.svg";
import { ReactComponent as StackVert24line } from "../../assets/icons/Stack-Vert-24-line.svg";
import { ReactComponent as BookOpen24line } from "../../assets/icons/Book-Open-24-line.svg";
import { ReactComponent as ArrowDiag24line } from "../../assets/icons/Arrow-Diag-24-line.svg";
import { ReactComponent as Union } from "../../assets/icons/Union.svg";
import { ReactComponent as SceneFrame } from "../../assets/icons/scene-frame.svg";
import { ReactComponent as SceneFrameWhite } from "../../assets/icons/scene-frame-white.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";
import { ReactComponent as Post24line } from "../../assets/icons/Post-24-line.svg";
import { ReactComponent as VideoCamRec24line } from "../../assets/icons/Video-Cam-Rec-24-line.svg";
import { ReactComponent as Mail24line } from "../../assets/icons/Mail-24-line.svg";
import { ReactComponent as BrandLogo } from "../../assets/icons/Brand-logo.svg";
import { ReactComponent as TelegramPromo } from "../../assets/icons/Telegram-Promo.svg";
import { ReactComponent as ModelHint } from "../../assets/icons/Model-Hint.svg";
import { ReactComponent as ModelHintText } from "../../assets/icons/Model-Hint-Text.svg";
import { ReactComponent as BaseScene } from "../../assets/icons/Base-scene.svg";

export const ICONS = {
  cloud_download_24: CloudDownload24,
  copy_line_24: CopyLine24,
  cube_24_logo: Cube24Logo,
  close_24_line: Close24line,
  arrow_slide_left: ArrowSlideLeft,
  arrow_slide_right: ArrowSlideRight,
  disclosure_up_16_line: DisclosureUp16line,
  disclosure_down_16_line: DisclosureDown16line,

  cube_24: Cube24,
  full_screen_square_24_line: FullScreenSquare24line,
  play_24_line: Play24line,

  three_dots_24: ThreeDots24,
  arrow_right_24_line: ArrowRight24line,
  arrow_left_24_line: ArrowLeft24line,

  search_24: Search24,
  search_24_fill: Search24Fill,

  stack_vert_24_line: StackVert24line,
  book_open_24_line: BookOpen24line,
  arrow_diag_24_line: ArrowDiag24line,

  union: Union,
  phone: Phone,

  scene_frame: SceneFrame,
  scene_frame_white: SceneFrameWhite,
  post_24_line: Post24line,
  video_cam_re_24_line: VideoCamRec24line,

  mail_24_line: Mail24line,

  brand_logo: BrandLogo,

  play: Play,

  telegram_promo: TelegramPromo,

  model_hint: ModelHint,
  model_hint_text: ModelHintText,

  base_scene: BaseScene,
};
