import { FC, useState } from "react";
import styled from "styled-components/macro";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { Button } from "@salutejs/plasma-b2c";

const Wrapper = styled.div`
  margin: 10px;
`;

const Editor = styled(CodeEditor)`
  width: 100%;
  box-sizing: border-box;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

type Props = {
  name: string;
  shader: string;
  onChangeShader: (name: string, value: string) => void;
};

export const ShaderEditor: FC<Props> = ({ name, shader, onChangeShader }) => {
  const [isShowEditor, setIsShowEditor] = useState<boolean>(false);
  return (
    <Wrapper>
      <StyledButton onClick={() => setIsShowEditor((prev) => !prev)}>
        {name}
      </StyledButton>

      <div>
        {isShowEditor && (
          <Editor
            language="js"
            name={name}
            value={shader}
            onChange={({ target: { value } }) => onChangeShader(name, value)}
            style={{
              fontSize: 14,
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
        )}
      </div>
    </Wrapper>
  );
};
