import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { Button } from "@salutejs/plasma-b2c";
import { Shaders } from "../../utils/types";
import { ShaderEditor } from "../ShaderEditor/ShaderEditor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  position: absolute;
  right: 0;
  width: 30vw;
  height: calc(100vh - 100px);
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
`;

type Props = {
  isOpenShadersModal: boolean;
  closeShadersModal: () => void;
  shaders: Shaders;
  setShaders: React.Dispatch<React.SetStateAction<Shaders>>;
};

export const ShadersModal: FC<Props> = ({
  isOpenShadersModal,
  closeShadersModal,
  shaders,
  setShaders,
}) => {
  const [currentShaders, setCurrentShaders] = useState<Shaders>(shaders);

  const shadersArray = useMemo(
    () => Object.entries(currentShaders),
    [currentShaders]
  );

  const onChangeShader = (name: string, value: string) => {
    setCurrentShaders((prev) => ({ ...prev, [name]: value }));
  };

  const saveShaders = () => {
    setShaders(currentShaders);
  };

  useEffect(() => {
    setCurrentShaders(shaders);
  }, [shaders]);

  if (!isOpenShadersModal) {
    return null;
  }

  return (
    <Wrapper>
      <Button view='checked' onClick={closeShadersModal}>закрыть окно шейдеров</Button>
      <p>Шейдеры</p>

      <div>
        {shadersArray.map(([name, shader], i) => (
          <ShaderEditor
            key={name}
            name={name}
            shader={shader}
            onChangeShader={onChangeShader}
          />
        ))}
      </div>
      <Button view='primary' onClick={saveShaders}>сохранить</Button>
    </Wrapper>
  );
};
