import { FC, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";

import { Body1, H1, H2, Button, Image } from "@salutejs/plasma-b2c";
import { background, blackPrimary } from "@salutejs/plasma-tokens-b2c";

import modelDefault from "../../assets/model.png";

import { IModel } from "../../utils/types";
import { useDropDownCardsPage } from "../../utils/hooks";

import { Icon } from "../Icon";
import { Cards } from "../Cards";
import { Header } from "../Header";

import { Model } from "./Model";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextBlockMain = styled(H1)`
  font-weight: 600;
  font-size: 56px;
  line-height: 56px;

  margin: 32px 10px 0;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.37),
      rgba(255, 255, 255, 0.37)
    ),
    linear-gradient(266.7deg, #22ded3 2.89%, #7626f9 102.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;

  width: calc(100% + 16px);
  margin-left: -8px;
  box-sizing: border-box;
  display: flex;
  padding-left: 16px;

  background: ${background};

  > div svg {
    fill: white;
  }
`;

const ModelWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 344/362;
  box-sizing: border-box;

  overflow: hidden;

  padding: 0 42px;

  margin-top: -40px;
`;

const FadeModel = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  width: calc(100% - 84px);
  aspect-ratio: 284/216;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`;

const ModelHint = styled.div`
  position: absolute;
  z-index: 11;
  aspect-ratio: 278/138;
  width: calc(100% - 156px);

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextAlgorithm = styled(H1)`
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;

  margin: 16px 16px 0;
`;

const WrapperOne = styled.div`
  width: calc(100% - 84px);
  padding: 0 16px;

  ${H1} {
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;

    margin-top: 64px;
  }

  ${H2} {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;

    margin-top: 12px;
    margin-bottom: 12px;
  }

  button {
    padding: 0;
  }
`;

const WrapperCreateVideo = styled.div`
  position: relative;
  margin-top: 20px;

  div:first-child {
    width: 100%;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${StyledImage} {
    z-index: 1;
    position: absolute;
    top: -50px;
    left: 50%;

    transform: translate(-50%);

    width: 59%;
    height: initial;
    aspect-ratio: 203/284;
  }
`;

const WrapperCreate3d = styled.div`
  margin-top: -16px;
  padding: 16px;
  padding-top: 48px;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 0px 0px 36px 36px;

  ${H2} {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;

    margin-top: 16px;
  }

  svg {
    width: 72px;
    height: 72px;
    fill: white;
  }
`;

const WrapperSecond = styled.div`
  background: white;
  border-radius: 44px;

  width: calc(100% + 16px);

  margin-top: 12px;
  margin-left: -8px;
  padding-top: 12px;

  ${H1} {
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
    padding-left: 16px;

    color: ${blackPrimary};
  }

  ${H2} {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: ${blackPrimary};

    padding-left: 16px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
`;

const ModelSecond = styled.div`
  position: relative;

  background: #27a5e5;
  border-radius: 36px;
  margin-left: 8px;
  margin-right: 8px;
  ${StyledImage} {
  }

  div:last-child {
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const BotName = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;

  ${Body1} {
    color: ${blackPrimary};
    padding-left: 16px;
  }
`;

const InternetShop = styled.div`
  background: #f1f5f9;
  border-radius: 36px;
  padding: 8px;
  margin: 8px;

  svg {
    width: 72px;
    height: 72px;
  }

  ${H2} {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;

    padding-left: 8px;
    margin-top: 16px;
  }
`;

const WrapperIntegration = styled.div`
  margin-top: 12px;

  padding: 24px 16px;

  border: 1px dashed #ffffff;
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${H1} {
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;

    margin-bottom: 12px;
  }

  ${Body1} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  button {
    padding: 0;
  }
`;

const WrapperModel = styled.div`
  margin-top: 64px;
  padding: 8px;

  > div:first-child {
    margin-bottom: 12px;
    margin-left: 16px;
  }

  > div:last-child {
    margin-left: 0;
  }
`;

interface Props {
  showModels: IModel[];
}

export const PromoMobile: FC<Props> = ({ showModels }) => {
  const [showModelHint, setShowModelHint] = useState<boolean>(true);

  const wrapper = useRef<HTMLDivElement>(null);
  const model = useMemo(() => showModels[0], [showModels]);

  const { dropdownItems, onItemSelect } = useDropDownCardsPage();

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header dropdownItems={dropdownItems} onItemSelect={onItemSelect} />
      </HeaderWrapper>

      <TextBlockMain>
        3D объекты <br />
        и сцены из видео
      </TextBlockMain>

      <ModelWrapper
        ref={wrapper}
        onTouchStart={() => setShowModelHint(false)}
        onTouchEnd={() => setShowModelHint(true)}
      >
        <Model isShow model={model} wrapper={wrapper} />

        {/* <StyledImage src={modelDefault} alt="model" /> */}

        {/* <FadeModel /> */}

        {showModelHint && (
          <ModelHint>
            <Icon name="model_hint" />
            <Icon name="model_hint_text" />
          </ModelHint>
        )}
      </ModelWrapper>

      <TextAlgorithm>
        Алгоритмическая обработка видео и превращение в модель
      </TextAlgorithm>

      <WrapperOne>
        <H1>Раз</H1>
        <H2>Сними видео вокруг объекта</H2>
        <Button
          view="clear"
          contentLeft={<Icon name="arrow_diag_24_line" />}
          text={<Body1>Как снимать</Body1>}
        />
      </WrapperOne>

      <WrapperCreateVideo>
        <Icon name="base_scene" />
        <StyledImage src={modelDefault} alt="model" />
      </WrapperCreateVideo>

      <WrapperCreate3d>
        <Icon name="video_cam_re_24_line" size={72} />
        <H2>
          Система может <br />
          по видеоряду воссоздать <br />
          3D модель
        </H2>
      </WrapperCreate3d>

      <WrapperSecond>
        <H1>Два</H1>
        <H2>Отправь видео боту Telegram</H2>

        <ModelSecond>
          <StyledImage src={modelDefault} alt="model" />
          <Icon name="play" />
        </ModelSecond>

        <BotName>
          <Icon name="telegram_promo" size={36} />
          <Body1>@BotName</Body1>
        </BotName>

        <InternetShop>
          <Icon name="post_24_line" size={72} />
          <H2>
            Подойдёт для съемки товаров
            <br />
            для интернет-магазинов
          </H2>
        </InternetShop>
      </WrapperSecond>

      <WrapperIntegration>
        <H1>Интеграция</H1>
        <Body1>
          Вы можете использовать сервис для оцифровки своих товаров, создания
          магазина и привлечения внимания
        </Body1>

        <Button
          view="clear"
          contentLeft={<Icon name="arrow_diag_24_line" />}
          text={<Body1>Напишите нам</Body1>}
        />

        <Button
          view="clear"
          contentLeft={<Icon name="mail_24_line" />}
          text={<Body1>examplemail@exmail.mail</Body1>}
        />
      </WrapperIntegration>

      <WrapperModel>
        <TextBlockMain>Модели</TextBlockMain>

        <Cards models={showModels} />
      </WrapperModel>
    </Wrapper>
  );
};
