import { FC, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";

import { H1, Image } from "@salutejs/plasma-b2c";
import { background } from "@salutejs/plasma-tokens-b2c";
import modelDefault from "../../assets/model.png";

import { IModel } from "../../utils/types";

import { PropsAnimate } from "./types";
import { generateStyleAnimate } from "./utils";
import { transition, widthOrHeightInitial } from "./constants";
import { Icon } from "../Icon";
import { Model } from "./Model";

const positions = [
  //0
  {
    width: 709,
    height: 800,
    left: 426,
    top: 0,
    opacity: 1,
  },

  //1
  {
    width: 240,
    height: 406,
    left: 796,
    top: 200,
    opacity: 1,
  },

  //2
  {
    width: 220,
    height: 350,
    left: 500,
    top: 198,
    opacity: 1,
  },

  //3
  {
    width: 211,
    height: 358,
    left: 700,
    top: 186,
    opacity: 1,
  },

  //4
  {
    width: 192,
    height: 326,
    left: 540,
    top: 180,
    opacity: 1,
  },

  //5
  {
    width: 174,
    height: 294,
    left: 563,
    top: 219,
    opacity: 0,
  },
];

const positionsModelHint = [
  //0
  {
    width: 278,
    left: 672,
    top: 438,
    opacity: 1,
  },

  //1
  {
    width: 200,
    left: 711,
    top: 438,

    opacity: 0,
  },
];

type Position = (typeof positions)[0];
type PositionModelHint = (typeof positionsModelHint)[0];

const WrapperAnimate = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 10;
  aspect-ratio: 240/406;

  border-radius: 32px;
  overflow: hidden;

  ${transition}

  ${({ currentAnimate }) =>
    generateStyleAnimate<Position>(
      positions[currentAnimate] || positions[positions.length - 1]
    )};

  ${({ currentAnimate }) => currentAnimate !== 0 && widthOrHeightInitial};

  > img {
    width: 100%;
    height: 100%;
  }

  ${H1} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const ModelHint = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 11;
  aspect-ratio: 278/138;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionModelHint>(
      positionsModelHint[currentAnimate] ||
        positionsModelHint[positionsModelHint.length - 1]
    )};

  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FadeLeft = styled.div`
  position: absolute;
  width: 70px;
  left: 0px;
  top: 0px;
  bottom: 0px;

  background: linear-gradient(90deg, ${background} 0%, rgba(0, 0, 0, 0) 100%);
`;

const FadeRight = styled(FadeLeft)`
  right: 0;
  left: initial;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

interface Props extends PropsAnimate {
  showModels: IModel[];
}

export const ModelAnimate: FC<Props> = ({ currentAnimate, showModels }) => {
  const [showModelHint, setShowModelHint] = useState<boolean>(true);

  const model = useMemo(() => showModels[0], [showModels]);

  const wrapper = useRef<HTMLDivElement>(null);

  if (currentAnimate > positions.length) {
    return null;
  }

  return (
    <WrapperAnimate
      ref={wrapper}
      currentAnimate={currentAnimate}
      onMouseDown={() => setShowModelHint(false)}
      onMouseUp={() => setShowModelHint(true)}
    >
      {currentAnimate === 0 && <FadeRight />}

      {showModelHint && (
        <ModelHint currentAnimate={currentAnimate}>
          <Icon name="model_hint" />
          <Icon name="model_hint_text" />
        </ModelHint>
      )}

      <Model isShow={currentAnimate === 0} model={model} wrapper={wrapper} />

      {currentAnimate !== 0 && <StyledImage src={modelDefault} alt="model" />}
    </WrapperAnimate>
  );
};
