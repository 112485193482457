import { FC, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { axiosScene } from "../utils/axios";
import { IModel, Paths } from "../utils/types";
import { init } from "../utils/MobileNerf";
import { unpack } from "../utils/unpack";

export const IframePage: FC = () => {
  const { uid } = useParams<{ uid: string }>();

  const [models, setModels] = useState<IModel[]>([]);
  const [paths, setPaths] = useState<Paths>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [extractedFile, setExtractedFile] = useState<boolean>(false);

  let [searchParams] = useSearchParams();

  const container = useRef<HTMLDivElement>(null);

  const getModels = async () => {
    try {
      const { data } = await axiosScene.get("/all_with_private");
      setModels(data);
    } catch (error) {
      console.log("---error", error);
    }
  };

  const getPaths = async () => {
    const foundModel = models.find((model) => model.uid === uid);

    if (!foundModel) {
      return;
    }

    const pathsData = await unpack({
      url: foundModel.path_obj_pre_phone,
      setProgress,
      setExtractedFile,
    });

    setPaths(pathsData);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!paths) {
      return;
    }

    const width = Number(searchParams.get("width")) || undefined;
    const height = Number(searchParams.get("height")) || undefined;
    const isAutoRotate = !!searchParams.get("autoRotate") || false;

    const isStopRender: any = init({
      paths,
      container: container.current,
      size_w: width,
      size_h: height,
      isShowFps: false,
      isAutoRotate,
      wrapper: null,

      containerHelpPosition: null,
    });

    isStopRender.isTrue = false;

    return () => {
      isStopRender.isTrue = true;
    };
  }, [paths, searchParams]);

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    if (models.length > 0) {
      getPaths();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return (
    <>
      {isLoading && (
        <h1
          style={{
            textAlign: "center",
            position: "absolute",
            left: 0,
            width: "100%",
            zIndex: 2,
            color: searchParams.get("color") || "black",
          }}
        >
          LOADING...
          {progress && progress !== 100 && `download file (${progress})%`}
          {extractedFile && "extracted File"}
        </h1>
      )}

      <div
        ref={container}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          background: searchParams.get("background") || undefined,
        }}
      />
    </>
  );
};
