import { FC, SVGProps, useMemo } from "react";
import styled from "styled-components/macro";

import { ICONS } from "./constants";
import { IconNames, IconProps } from "./types";

const Wrapper = styled.div<IconProps>`
  width: ${({ width }) => (width ? `${width}px` : "initial")};
  height: ${({ height }) => (height ? `${height}px` : "initial")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props extends IconProps {
  name: IconNames;
  size?: number;
  svgProps?: SVGProps<SVGSVGElement>;
}
export const Icon: FC<Props> = ({ name, size, svgProps, ...props }) => {
  const Component = useMemo(() => ICONS[name], [name]);

  const sizeProps = useMemo<IconProps | {}>(() => {
    if (size) {
      return { width: size, height: size };
    }

    const sizeFromName = name.match(/\d{1,}/g);
    if (sizeFromName) {
      return { width: sizeFromName[0], height: sizeFromName[0] };
    }

    return {};
  }, [name, size]);

  return (
    <Wrapper {...sizeProps}>
      <Component {...props} {...svgProps} />
    </Wrapper>
  );
};
