import { axiosCdnBaseUrl } from "./axios";
import { IModel, ModelPlyI, ModelPlyServer } from "./types";

export const chunking = <Type extends unknown>(
  array: Type[],
  quantityItemsInChunk: number
): Type[][] => {
  const res = [];
  for (let index = 0; index < array.length; index += quantityItemsInChunk) {
    const tempArr = array.slice(index, index + quantityItemsInChunk);
    res.push(tempArr);
  }

  return res;
};

export const downloadModel = (uid: string, path_obj_pre_phone: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);

  a.href = process.env.REACT_APP_CDN_URL + path_obj_pre_phone;
  a.download = uid;
  a.target = "_blank";
  a.click();
  a.remove();
};

export const filterModels = (models: IModel[], query: string | null) => {
  if (!query) {
    return models;
  }

  return models.filter((model: IModel) => {
    const modelDescription = model.description.toLowerCase();
    const modelCreator = `@${model.nickname.toLowerCase()}`;
    return modelDescription.includes(query) || modelCreator.includes(query);
  });
};

export const adapterModelPly = (data: ModelPlyServer): ModelPlyI => {
  return {
    ...data,
    modelParams: {
      ...data.modelParams,
      plyPath: axiosCdnBaseUrl + data.modelParams.plyPath,
    },
  };
};
