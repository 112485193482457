import * as THREE from "three";
import WebGL from "three/examples/jsm/capabilities/WebGL.js?module";
import Stats from "three/addons/libs/stats.module.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js?module";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js?module";
import {
  render_vert as render_vert_initial,
  gbuffer_vert as gbuffer_vert_initial,
  gbuffer_frag as gbuffer_frag_initial,
  viewDependenceNetworkShaderFunctionsF,
} from "./shaders";

import { OrbitControls } from "./ThreeOrbitControlsGizmo/OrbitControls.js";
import { OrbitControlsGizmo } from "./ThreeOrbitControlsGizmo/OrbitControlsGizmo.js";
import { ShaderVersions } from "./constants";

// предел вращения по вертикали в градусах от 0 до 360
const MAX_POLAR_ANGLE = Number(process.env.REACT_APP_MAX_POLAR_ANGLE || 0);

function createNetworkWeightTexture(network_weights) {
  let width = network_weights.length;
  let height = network_weights[0].length;

  let weightsData = new Float32Array(width * height);
  for (let co = 0; co < height; co++) {
    for (let ci = 0; ci < width; ci++) {
      let index = co * width + ci;
      let weight = network_weights[ci][co];
      weightsData[index] = weight;
    }
  }
  let texture = new THREE.DataTexture(
    weightsData,
    width,
    height,
    THREE.RedFormat,
    THREE.FloatType
  );
  texture.magFilter = THREE.NearestFilter;
  texture.minFilter = THREE.NearestFilter;
  texture.needsUpdate = true;
  return texture;
}

export function createViewDependenceFunctions(network_weights, shaderVersion) {
  if (!shaderVersion) {
    return "";
  }

  const createBias = (str) => {
    let width = network_weights[str].length;
    let biasList = "";
    for (let i = 0; i < width; i++) {
      let bias = network_weights[str][i];
      biasList += Number(bias).toFixed(7);
      if (i + 1 < width) {
        biasList += ", ";
      }
    }

    return biasList;
  };
  const regexListForShaderDefault = [
    ///TINT_DENSE
    {
      str: "NUM_CHANNELS_TINT",
      num: network_weights["tint_dense_layer_bias"].length,
    },
    {
      str: "BIAS_TINT_DENSE",
      num: createBias("tint_dense_layer_bias"),
    },

    {
      str: "NUM_CHANNELS_DIFFUSE",
      num: network_weights["diffuse_dense_layer_bias"].length,
    },
    {
      str: "BIAS_DIFFUSE_DENSE",
      num: createBias("diffuse_dense_layer_bias"),
    },
  ];

  let regexListForShader;

  switch (shaderVersion) {
    case ShaderVersions.first:
      regexListForShader = [
        ...regexListForShaderDefault,

        {
          str: "NUM_CHANNELS_ZERO",
          num: network_weights["0_weights"].length,
        },

        {
          str: "NUM_CHANNELS_ONE",
          num: network_weights["0_bias"].length,
        },

        {
          str: "NUM_CHANNELS_TWO",
          num: network_weights["1_bias"].length,
        },

        {
          str: "NUM_CHANNELS_THREE",
          num: network_weights["2_bias"].length,
        },

        {
          str: "BIAS_LIST_ZERO",
          num: createBias("0_bias"),
        },

        {
          str: "BIAS_LIST_ONE",
          num: createBias("1_bias"),
        },

        {
          str: "BIAS_LIST_TWO",
          num: createBias("2_bias"),
        },
      ];
      break;

    case ShaderVersions.second:
      regexListForShader = [
        ...regexListForShaderDefault,
        {
          str: "NUM_CHANNELS_ZERO",
          num: network_weights["0_weights"].length,
        },

        {
          str: "NUM_CHANNELS_ONE",
          num: network_weights["0_bias"].length,
        },

        {
          str: "NUM_CHANNELS_THREE",
          num: network_weights["2_bias"].length,
        },

        {
          str: "BIAS_LIST_ZERO",
          num: createBias("0_bias"),
        },

        {
          str: "BIAS_LIST_TWO",
          num: createBias("2_bias"),
        },
      ];
      break;

    default:
      break;
  }

  let fragmentShaderSource =
    viewDependenceNetworkShaderFunctionsF(shaderVersion);

  regexListForShader.forEach(({ str, num }) => {
    fragmentShaderSource = fragmentShaderSource.replace(
      new RegExp(str, "g"),
      num
    );
  });

  return fragmentShaderSource;
}

let showFPS = Number(process.env.REACT_APP_SHOW_FPS) || 0;

let containerRef, camera, scene, renderer, controls, clock, wrapperRef;
let renderTarget, stats;
let postScene, postCamera;

const config = {
  isStopRender: false,
  autoRotate: true,
};

let preset_size_w = window.innerWidth;
let preset_size_h = window.innerHeight;
const object_rescale = 1.0;

let near_plane = 0.5;
let mesh;

let countTexture = 0;

const textureLoaderMap = (map) => {
  map.flipY = false;
  countTexture++;
};

let object;

export function init({
  paths,
  container,
  containerHelpPosition,
  wrapper,
  size_w = 0,
  size_h = 0,
  isShowFps = true,
  isAutoRotate = false,
  minDistance = 3,
  maxDistance = 20,
  render_vert = render_vert_initial,
  gbuffer_vert = gbuffer_vert_initial,
  gbuffer_frag = gbuffer_frag_initial,
  fragmentShaderSource = undefined,
}) {
  const PNGFile0 = paths.pngfeat0;
  const PNGFile1 = paths.pngfeat1;
  const NORMALFile = paths.normalsfile;
  const JSONFile = paths.mlpJson;
  const MeshFile = paths.mesh;

  containerRef = container;
  wrapperRef = wrapper;

  preset_size_w = wrapper?.offsetWidth || size_w || preset_size_w;
  preset_size_h = wrapper?.offsetHeight || size_h || preset_size_h;

  config.autoRotate = isAutoRotate;

  showFPS = showFPS && isShowFps;

  clock = new THREE.Clock();

  near_plane = 0.5;

  if (WebGL.isWebGL2Available() === false) {
    document.body.appendChild(WebGL.getWebGL2ErrorMessage());
    return;
  }

  renderer = new THREE.WebGLRenderer({
    powerPreference: "high-performance",
    precision: "highp",
    alpha: true,
  });
  renderer.setPixelRatio(0.8);
  renderer.setSize(preset_size_w, preset_size_h);

  renderer.domElement.style.margin = "0";
  renderer.domElement.style.width = "100%";
  renderer.domElement.style.height = "100%";

  containerRef.appendChild(renderer.domElement);

  renderTarget = new THREE.WebGLMultipleRenderTargets(
    preset_size_w * 2,
    preset_size_h * 2,
    4
  );

  for (let i = 0, il = renderTarget.texture.length; i < il; i++) {
    renderTarget.texture[i].minFilter = THREE.LinearFilter;
    renderTarget.texture[i].magFilter = THREE.LinearFilter;
    renderTarget.texture[i].type = THREE.FloatType;
  }

  camera = new THREE.PerspectiveCamera(
    47,
    preset_size_w / preset_size_h,
    near_plane * object_rescale,
    25 * object_rescale
  );

  camera.position.set(0, 3, 9);

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.screenSpacePanning = true;
  controls.maxPolarAngle = Math.PI - (MAX_POLAR_ANGLE * Math.PI) / 180;
  controls.minDistance = minDistance;
  controls.maxDistance = maxDistance;

  scene = new THREE.Scene();

  const controlsGizmo = new OrbitControlsGizmo(controls, {
    size: 80,
    padding: 6,
  });

  if (containerHelpPosition) {
    containerHelpPosition.firstChild?.remove();
    containerHelpPosition.appendChild(controlsGizmo.domElement);
  }

  fetch(JSONFile)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      for (let i = 0, il = json["obj_num"]; i < il; i++) {
        let tex0 = new THREE.TextureLoader().load(PNGFile0, textureLoaderMap);
        let tex1 = new THREE.TextureLoader().load(PNGFile1, textureLoaderMap);

        let tex_normal = new THREE.TextureLoader().load(
          NORMALFile,
          textureLoaderMap
        );

        let newmat = new THREE.RawShaderMaterial({
          side: THREE.DoubleSide,
          vertexShader: gbuffer_vert,
          fragmentShader: gbuffer_frag,
          uniforms: {
            tDiffuse0: { value: tex0 },
            tDiffuse1: { value: tex1 },
            tNormals: { value: tex_normal },
          },
          glslVersion: THREE.GLSL3,
        });

        const loader = new GLTFLoader();

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        loader.setDRACOLoader(dracoLoader);

        // eslint-disable-next-line no-loop-func
        loader.load(MeshFile, function (obj) {
          object = obj.scene;
          let scale = 1.0;

          object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material = newmat;
              child.geometry.center();
              child.geometry.computeBoundingSphere();
              scale = 0.2 * child.geometry.boundingSphere.radius;
              mesh = new THREE.Mesh(child.geometry, child.material);
            }
          });
          object.scale.divideScalar(scale);

          let object_offset_x = 0.0;
          let object_offset_y = 0.0;
          let object_offset_z = 0.0;
          if (
            json.hasOwnProperty("object_offset_z") &&
            json.hasOwnProperty("object_offset_y") &&
            json.hasOwnProperty("object_offset_x")
          ) {
            object_offset_z = json["object_offset_z"];
            object_offset_y = json["object_offset_y"];
            object_offset_x = json["object_offset_x"];
          } else {
            console.log("There is no object_offset_xyz in scene json");
          }

          mesh.position.x = object_offset_x;
          mesh.position.y = object_offset_y;
          mesh.position.z = object_offset_z;

          controls.target.set(
            object_offset_x,
            object_offset_y,
            object_offset_z
          );
          controls.update();

          if (
            json.hasOwnProperty("init_camera_pos_x") &&
            json.hasOwnProperty("init_camera_pos_y") &&
            json.hasOwnProperty("init_camera_pos_z")
          ) {
            let init_camera_pos_x = json["init_camera_pos_x"];
            let init_camera_pos_y = json["init_camera_pos_y"];
            let init_camera_pos_z = json["init_camera_pos_z"];

            camera.position.set(
              init_camera_pos_x,
              init_camera_pos_y,
              init_camera_pos_z
            );
            controls.update();
          } else {
            console.log("There is no init_camera_pos_xyz in scene json");
          }

          scene.add(mesh);
        });

        if (
          json.hasOwnProperty("min_azimuth") &&
          json.hasOwnProperty("max_azimuth")
        ) {
          if (json["min_azimuth"] !== "inf") {
            controls.minAzimuthAngle = json["min_azimuth"];
          }
          if (json["max_azimuth"] !== "inf") {
            controls.maxAzimuthAngle = json["max_azimuth"];
          }
        }
      }

      let shaderVersion = ShaderVersions.first;
      if (
        json.hasOwnProperty("0_weights") &&
        json.hasOwnProperty("1_weights") &&
        json.hasOwnProperty("2_weights")
      ) {
        shaderVersion = ShaderVersions.first;
        console.log("Old version. ALl 3 weights exists");
      } else {
        shaderVersion = ShaderVersions.second;
        console.log("New version with smaller 2 layer mlp");
      }

      let network_weights = json;

      fragmentShaderSource =
        fragmentShaderSource ??
        createViewDependenceFunctions(network_weights, shaderVersion);

      const weightsListDefault = [
        // second TINT_DENSE_LAYER
        { key: "weightsTint", value: "tint_dense_layer_weights" },
        // fifth DIFFUSE_DENSE_LAYER
        { key: "weightDiffuse", value: "diffuse_dense_layer_weights" },
      ];

      const weightsListV1 = [
        { key: "weightsZero", value: "0_weights" },
        { key: "weightsOne", value: "1_weights" },
        { key: "weightsTwo", value: "2_weights" },
        ...weightsListDefault,
      ];

      const weightsListV2 = [
        { key: "weightsZero", value: "0_weights" },
        { key: "weightsTwo", value: "2_weights" },
        ...weightsListDefault,
      ];

      let weightsList = weightsListV1;

      switch (shaderVersion) {
        case ShaderVersions.first:
          weightsList = weightsListV1;
          break;

        case ShaderVersions.second:
          weightsList = weightsListV2;
          break;

        default:
          break;
      }

      const uniformsWeights = Object.values(weightsList).reduce(
        (acc, { key, value }) => {
          return {
            ...acc,
            [key]: {
              value: createNetworkWeightTexture(network_weights[value]),
            },
          };
        },
        {}
      );

      const uniforms = {
        tDiffuse0x: { value: renderTarget.texture[0] },
        tDiffuse1x: { value: renderTarget.texture[1] },
        tDiffuse2x: { value: renderTarget.texture[2] },
        tNormals: { value: renderTarget.texture[3] },

        ...uniformsWeights,
      };

      postScene = new THREE.Scene();
      postCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

      postScene.add(
        new THREE.Mesh(
          new THREE.PlaneGeometry(2, 2),
          new THREE.RawShaderMaterial({
            vertexShader: render_vert,
            fragmentShader: fragmentShaderSource,
            uniforms,
            glslVersion: THREE.GLSL3,
          })
        )
      );

      stats = new Stats();

      if (showFPS) {
        stats.dom.setAttribute("id", "stats");
        stats.dom.removeAttribute("style");
        stats.dom.style.position = "absolute";
        stats.dom.style.top = "5rem";
        stats.dom.style.right = "10px";
        stats.dom.style.cursor = "pointer";
        containerRef.appendChild(stats.dom);
      }

      window.addEventListener(
        "resize",
        () => onWindowResize(size_w, size_h),
        false
      );

      animate();
    });

  return config;
}

export function onWindowResize(size_w, size_h) {
  const w = wrapperRef.offsetWidth || size_w || window.innerWidth;
  const h = wrapperRef.offsetHeight || size_h || window.innerHeight;

  camera.aspect = w / h;
  camera.updateProjectionMatrix();

  renderer.setSize(w, h);

  renderTarget.setSize(w * 2, h * 2);

  render();
}

function animate() {
  if (config.isStopRender) {
    unload();
    return;
  }
  requestAnimationFrame(animate);

  const delta = clock.getDelta();

  if (mesh && config.autoRotate) {
    mesh.rotation.y += delta * 0.5;
  }

  controls.update();

  render();
  stats.update();
}

function unload() {
  object.traverse(function (obj) {
    scene.remove(obj);

    if (obj.geometry) obj.geometry.dispose();
    if (obj.material) obj.material.dispose();
    if (obj.mesh) obj.mesh.dispose();
    if (obj.texture) obj.texture.dispose();
  });
  scene.remove(object);
}

function render() {
  if (config.isStopRender || countTexture < 2) {
    return;
  }
  // render scene into target
  renderer.setRenderTarget(renderTarget);
  renderer.render(scene, camera);

  // render post FX
  renderer.setRenderTarget(null);
  renderer.render(postScene, postCamera);
}
