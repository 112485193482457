import { FC, useEffect, useState } from "react";
import styled from "styled-components/macro";

import { IModel } from "../../utils/types";
import { DISABLED_PROMO } from "../../utils/constants";

import { ModelAnimate } from "./ModelAnimate";
import { MainBlock } from "./MainBlock";
import { JustCreateVideo } from "./JustCreateVideo";
import { SendToBot } from "./SendToBot";
import { Integration } from "./Integration";
import { ShowModel } from "./ShowModel";
import { Grid } from "./Grid";
import { Logo } from "./Logo";

import { pxToPercentHeight } from "./utils";
import { scrollTarget } from "./constants";

const Wrapper = styled.div`
  width: 100%;
  min-height: ${pxToPercentHeight(
    scrollTarget[scrollTarget.length - 1] + 4100
  )};
`;

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;
interface Props {
  showModels: IModel[];
}

export const Promo: FC<Props> = ({ showModels }) => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [currentAnimate, setCurrenAnimate] = useState<number>(0);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollTop = document.documentElement.scrollTop;
      const num = scrollTarget.findIndex((el) => el > scrollTop);
      setCurrenAnimate(num === -1 ? scrollTarget.length : num);
      setScrollTop(scrollTop);
    };

    document.addEventListener("scroll", scrollHandler);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (currentAnimate > 5) {
      localStorage.setItem(DISABLED_PROMO, "true");
    }
  }, [currentAnimate]);

  return (
    <Wrapper>
      <Container>
        <Logo currentAnimate={currentAnimate} />

        <ModelAnimate currentAnimate={currentAnimate} showModels={showModels} />

        <MainBlock currentAnimate={currentAnimate} />

        <JustCreateVideo currentAnimate={currentAnimate} />

        <SendToBot currentAnimate={currentAnimate} />

        <Integration currentAnimate={currentAnimate} />

        <ShowModel currentAnimate={currentAnimate} />

        <Grid
          scrollTop={scrollTop}
          currentAnimate={currentAnimate}
          showModels={showModels}
        />
      </Container>
    </Wrapper>
  );
};
