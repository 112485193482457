import { FC } from "react";
import styled from "styled-components/macro";
import { Body1, H1, Button } from "@salutejs/plasma-b2c";
import { primary } from "@salutejs/plasma-tokens-b2b/colors/values";

import { Icon } from "../Icon";
import { PropsAnimate } from "./types";
import { transition } from "./constants";
import { generateStyleAnimate, pxToPercentHeight } from "./utils";

const positionsTextBlockMain = [
  //0
  {
    width: 346,
    left: 64,
    top: 441,
    opacity: 0,
  },

  //1
  {
    width: 346,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //2
  {
    width: 346,
    left: 64,
    top: 36,
    opacity: 0,
  },
];

const positionsBackgroundMain = [
  //0
  {
    width: 500,
    height: 200,
    left: 450,
    top: 200,
    opacity: 0,
  },

  //1
  {
    width: 1199,
    height: 800,
    left: 0,
    top: 0,
    opacity: 1,
  },

  //2
  {
    width: 1199,
    height: 800,
    left: 0,
    top: 0,
    opacity: 1,
  },

  //3
  {
    width: 1199,
    height: 800,
    left: 0,
    top: 0,
    opacity: 0,
  },
];

const positionsTelegram = [
  //0
  {
    width: 96,
    left: 617,
    top: 274,
    opacity: 0,
  },

  //1
  {
    width: 144,
    left: 877,
    top: 227,
    opacity: 1,
  },

  //3
  {
    width: 144,
    left: 715,
    top: 233,
    opacity: 0,
  },
];

type PositionTextBlockMain = typeof positionsTextBlockMain[0];
type PositionBackgroundMain = typeof positionsBackgroundMain[0];
type PositionTelegram = typeof positionsTelegram[0];

const TextBlockMain = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockMain>(
      positionsTextBlockMain[currentAnimate - 2] ||
        positionsTextBlockMain[positionsTextBlockMain.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${H1} {
    margin-bottom: ${pxToPercentHeight(20)};
    font-size: 64px;
    line-height: 68px;

    color: ${primary};
  }

  ${Body1} {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: ${primary};
  }

  > button {
    padding: 0;
    width: fit-content;
  }
`;

const BackgroundMain = styled.div<PropsAnimate>`
  position: absolute;
  background: white;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionBackgroundMain>(
      positionsBackgroundMain[currentAnimate - 2] ||
        positionsBackgroundMain[positionsBackgroundMain.length - 1]
    )};

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const Telegram = styled.div<PropsAnimate>`
  position: absolute;
  aspect-ratio: 1/1;
  z-index: 9;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTelegram>(
      positionsTelegram[currentAnimate - 2] ||
        positionsTelegram[positionsTelegram.length - 1]
    )};
`;

export const SendToBot: FC<PropsAnimate> = ({ currentAnimate }) => {
  return (
    <>
      <TextBlockMain currentAnimate={currentAnimate}>
        <H1>Отправь видео боту в Telegram</H1>

        <Body1>
          Просто снимите видео вокруг объекта, отправьте его в телеграм-бота
          и получите 3d модель
        </Body1>

        <Button
          view="clear"
          contentLeft={
            <Icon name="arrow_diag_24_line" svgProps={{ fill: primary }} />
          }
          text={<Body1>Telegram</Body1>}
        />
      </TextBlockMain>

      <BackgroundMain currentAnimate={currentAnimate} />
      <Telegram currentAnimate={currentAnimate}>
        <Icon name="telegram_promo" />
      </Telegram>
    </>
  );
};
