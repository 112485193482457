import { IModel } from "../../utils/types";
import { FoundStr } from "./types";

export const filterModels = (models: IModel[], query: string | null) => {
  if (!query) {
    return models;
  }

  return models.filter((model: IModel) => {
    const modelDescription = model.description.toLowerCase();
    const modelCreator = `@${model.nickname.toLowerCase()}`;
    return modelDescription.includes(query) || modelCreator.includes(query);
  });
};

const includeFindStr = (arr: FoundStr[], str: string) => {
  return arr.some(({ baseStr }) => baseStr === str);
};

export const addSuggestion = ({
  index,
  modelValue,
  searchValue,
  res,
}: {
  index: number;
  searchValue: string;
  modelValue: string;
  res: FoundStr[];
}) => {
  if (
    index !== -1 &&
    !includeFindStr(res, modelValue) &&
    modelValue !== searchValue
  ) {
    const beforeStr = modelValue.substring(0, index);
    const afterStr = modelValue.slice(index + searchValue.length);
    const obj = {
      beforeStr,
      searchValue,
      afterStr,
      baseStr: modelValue,
    };

    res.push(obj);
  }
};
