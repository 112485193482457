import styled from "styled-components/macro";
import { FC, useEffect, useRef } from "react";

import { Viewer } from "./Viewer";
import { ModelParams } from "../../utils/types";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  /* aspect-ratio: 900/1200; */

  > canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

interface Props {
  modelParams: ModelParams;
}

export const ModelPlyThree: FC<Props> = ({ modelParams }) => {
  const {
    cameraUp,
    initialCameraPos,
    initialCameraLookAt,
    cameraSpecs,
    plyPath,
  } = modelParams;
  const root = useRef<HTMLDivElement>(null);

  const initViewer = () => {
    const viewer = new Viewer({
      rootElement: root.current,

      callbackMesh: () => {},

      cameraUp,
      initialCameraPos,
      initialCameraLookAt,

      cameraSpecs,
    });
    viewer.init();
    viewer.loadFile(plyPath).then(() => {
      viewer.start();
    });
  };

  useEffect(() => {
    initViewer();
  }, []);

  return (
    <Wrapper ref={root}>{/* <Scene root={root.current} />{" "} */}</Wrapper>
  );
};
