import { FC, useEffect, useMemo, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";

import { BodyS, Button, H1, H2, H3, TextField } from "@salutejs/plasma-b2c";
import {
  background,
  headline1,
  link,
  tertiary,
} from "@salutejs/plasma-tokens-b2c";

import { useStateContext } from "../../utils/context";
import { SIZES } from "../../utils/breakpoints";

import { Icon } from "../Icon";

import { addSuggestion, filterModels } from "./utils";
import { FoundStr } from "./types";

const MAX_LENGTH_SUGGESTION_LIST = 3;

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
`;

const animation = css`
  animation-duration: 0.3s;
  animation-name: ${opacity};
`;

const WrapperSearch = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: ${background};

  display: flex;
  z-index: 1;

  ${animation}

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  @media (max-width: ${SIZES.md}px) {
    position: initial;
    height: fit-content;
    margin-bottom: 24px;
    padding-top: 0;
  }
`;

const StyledTextField = styled(TextField)`
  background: none;
  padding-left: 0;

  input {
    background: none;
    padding-left: 0 !important;
    font-size: ${headline1.fontSize} !important;
    line-height: ${headline1.lineHeight} !important;
    font-family: "SB Sans Text" !important;
  }

  input:focus {
    background: none !important;
  }

  label {
    padding-left: 0 !important;
    font-size: ${headline1.fontSize} !important;
  }
`;

const ButtonIcon = styled(Button)`
  width: 40px;
  min-width: 40px;
  padding: 0;
  margin-right: 4px;
`;

const SuggestionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`;

const Suggestion = styled(H3)`
  cursor: pointer;

  ${H3} {
    color: ${link};
    display: inline;
  }
`;

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 3;
  padding-top: 5px;

  ${animation}

  ${H2} {
    margin-left: 6px;
  }

  @media (max-width: ${SIZES.md}px) {
    margin-bottom: 26px;
  }
`;

const StyledDescription = styled(BodyS)`
  color: ${tertiary};
  margin: 24px 0 16px;
`;

const DefaultSuggestions = ["футбол", "кварц", "panda"];

interface Props {}

export const SearchBar: FC<Props> = () => {
  const { models, setFilteredModels, setSearchQuery } = useStateContext();

  const [searchValue, setSearchValue] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    setFilteredModels(filterModels(models, searchValue));
    setSearchQuery(searchValue);
  }, [models, searchValue, setFilteredModels, setSearchQuery]);

  const suggestionList = useMemo(() => {
    const res: FoundStr[] = [];

    if (!searchValue) {
      return res;
    }

    for (let i = 0; i < models.length; i++) {
      if (MAX_LENGTH_SUGGESTION_LIST <= res.length) {
        return res;
      }

      const model = models[i];

      const modelDescription = model.description.toLowerCase();
      const modelNickname = model.nickname.toLowerCase();

      const indexDescription = modelDescription.indexOf(searchValue);
      const indexNickname = modelNickname.indexOf(searchValue);

      if (indexDescription === -1 && indexNickname === -1) {
        continue;
      }

      addSuggestion({
        searchValue,
        res,
        index: indexDescription,
        modelValue: modelDescription,
      });

      if (MAX_LENGTH_SUGGESTION_LIST <= res.length) {
        return res;
      }

      addSuggestion({
        searchValue,
        res,
        index: indexNickname,
        modelValue: modelNickname,
      });
    }
    return res;
  }, [models, searchValue]);

  return showSearch ? (
    <>
      <WrapperSearch>
        <ButtonIcon
          view="clear"
          text={<Icon name="arrow_left_24_line" size={40} />}
          onClick={() => setShowSearch(false)}
        />

        <div>
          <StyledTextField
            autoFocus
            placeholder="Поиск"
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />

          {!searchValue && (
            <>
              <StyledDescription>
                Например, гипсовая голова, статуя, кружка т.д.
              </StyledDescription>

              <SuggestionListWrapper>
                {DefaultSuggestions.map((suggestion) => (
                  <Suggestion
                    bold={false}
                    key={suggestion}
                    onClick={() => setSearchValue(suggestion)}
                  >
                    {suggestion}
                  </Suggestion>
                ))}
              </SuggestionListWrapper>
            </>
          )}

          <SuggestionListWrapper>
            {suggestionList.map(
              ({ beforeStr, searchValue, afterStr, baseStr }) => (
                <Suggestion
                  bold={false}
                  key={baseStr}
                  onClick={() => setSearchValue(baseStr)}
                >
                  {beforeStr}
                  <H3 bold={false}>{searchValue}</H3>
                  {afterStr}
                </Suggestion>
              )
            )}
          </SuggestionListWrapper>
        </div>
      </WrapperSearch>
    </>
  ) : (
    <StyledSearch>
      {/* <Icon name="search_24_fill" size={40} /> */}
      <H1 bold={false} onClick={() => setShowSearch(true)}>
        Поиск
      </H1>
    </StyledSearch>
  );
};
