import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { Button, H1 } from "@salutejs/plasma-b2c";
import { tertiary } from "@salutejs/plasma-tokens-b2c";

import { SIZES } from "../../utils/breakpoints";
import { useDropDownCardsPage, useWindowSize } from "../../utils/hooks";

import { SearchBar } from "../SearchBar";
import { Header } from "../Header";

const Wrapper = styled.div`
  color: white;
  min-width: 346px;
  max-width: 346px;
  position: relative;
  height: fit-content;

  grid-area: panel;
  position: sticky;
  top: 24px;

  > div {
    &:first-child {
      margin-bottom: 32px;
    }
  }

  @media (max-width: ${SIZES.xl}px) {
    min-width: 346px;
    max-width: 346px;
  }

  @media (max-width: ${SIZES.lg}px) {
    min-width: 240px;
    max-width: 240px;
  }

  @media (max-width: ${SIZES.sm}px) {
    min-width: 100%;
    max-width: 100%;
    margin-top: 0;
    position: inherit;

    > div {
      &:first-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonIcon = styled(Button)`
  width: 100%;
  justify-content: flex-start;

  padding: 0;
  margin-bottom: 32px;

  :hover {
    text-decoration-line: underline;
  }
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${tertiary};
  margin: 26px 0 26px;
`;

type Props = {
  isLabel?: boolean;
};

export const SidePanel: FC<Props> = ({ isLabel = true }) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const { dropdownItems, onItemSelect } = useDropDownCardsPage();

  return (
    <Wrapper>
      {isLabel && (
        <Header dropdownItems={dropdownItems} onItemSelect={onItemSelect} />
      )}

      <SearchBar />

      {!isMobile && (
        <>
          {/* <Line /> */}

          <ButtonsWrapper>
            <ButtonIcon
              view="clear"
              text={<H1 bold={false}>Все модели</H1>}
              // contentLeft={<Icon name="stack_vert_24_line" size={40} />}
              onClick={() => navigate(`/all`)}
            />
            <ButtonIcon
              view="clear"
              text={<H1 bold={false}>Инструкция</H1>}
              // contentLeft={<Icon name="book_open_24_line" size={40} />}
            />
            <ButtonIcon
              view="clear"
              text={<H1 bold={false}>О сервисе</H1>}
              // contentLeft={<Icon name="arrow_diag_24_line" size={40} />}
            />
          </ButtonsWrapper>
        </>
      )}
    </Wrapper>
  );
};
