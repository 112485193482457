import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ModelPage } from "./pages/ModelPage";
import { HomePage } from "./pages/HomePage";
import { AllModelsPage } from "./pages/AllModelsPage";
import { UserModelPage } from "./pages/UserModelPage";
import { IframePage } from "./pages/IframePage";
import { Ply } from "./pages/Ply";

import { Scene } from "./components/Scene";

import { PATHS } from "./utils/constants";

import { StateProvider } from "./utils/context";
import { DebuggingModelPage } from "./pages/DebuggingModelPage";
import { GlobalStyle } from "./components/GlobalStyle";
import { ModelPageGlb } from "./pages/ModelPageGlb";
import { IframePageGlb } from "./pages/IframePageGlb";

const isDev = Number(process.env.REACT_APP_IS_DEV) || false;

const paths = [
  {
    path: PATHS.home,
    element: <HomePage />,
  },
  {
    path: PATHS.all,
    element: <AllModelsPage />,
  },
  {
    path: PATHS.userModel,
    element: <UserModelPage />,
  },
  {
    path: PATHS.model,
    element: <ModelPage />,
  },
  {
    path: PATHS.iframe,
    element: <IframePage />,
  },

  {
    path: PATHS.iframeGlb,
    element: <IframePageGlb />,
  },

  // {
  //   path: PATHS.debuggingModel,
  //   element: <DebuggingModelPage />,
  //   isOnlyDev: true,
  // },
  // {
  //   path: PATHS.scene,
  //   element: <Scene />,
  //   isOnlyDev: true,
  // },
  {
    path: PATHS.ply,
    element: <Ply />,
    isOnlyDev: false,
  },
  {
    path: PATHS.plyUid,
    element: <Ply />,
  },
  {
    path: PATHS.glb,
    element: <ModelPageGlb />,
  },
].filter(({ isOnlyDev }) => isDev || !isOnlyDev);

export const App: FC = () => {
  return (
    <StateProvider>
      <BrowserRouter>
        <GlobalStyle />
        <Routes>
          {paths.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
};
