import { createGlobalStyle, css } from "styled-components/macro";

import { b2c } from "@salutejs/plasma-tokens-b2c/typo";
import { dark } from "@salutejs/plasma-tokens-b2c/themes";
import {
  background,
  surfaceLiquid02,
  surfaceLiquid03,
  transparent,
  white,
} from "@salutejs/plasma-tokens-b2c";
import {
  compatible as compatibleType,
  standard as standardTypo,
} from "@salutejs/plasma-typo";

const scrollBarCss = css`
  ::-webkit-scrollbar {
    width: 16px;
    background-color: ${transparent};
    border-radius: 0px;
  }

  ::-webkit-scrollbar-corner {
    background-color: ${transparent};
  }

  ::-webkit-scrollbar:hover {
    background-color: ${transparent};
  }

  ::-webkit-scrollbar-thumb {
    // Probably min-properties doesn't work
    &:vertical {
      min-height: 10px;
    }

    &:horizontal {
      min-width: 10px;
    }

    &:vertical,
    &:horizontal {
      background-color: ${surfaceLiquid02};
      border-radius: 8px;
      background-clip: padding-box;
      border: 4px solid ${transparent};

      &:active,
      &:hover {
        background-color: ${surfaceLiquid03};
      }
    }
  }
`;

const inputColor = css`
  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 32px;
    height: 32px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
`;

const DocumentStyle = createGlobalStyle`
    html {
        color: ${white};
        background-color: ${background};
    }

    ${scrollBarCss}
    
    ${inputColor}
`;

const PlasmaStyle = createGlobalStyle(dark, compatibleType, b2c, standardTypo);

export const FONT_FAMILY = [
  "SB Sans Text",
  "OpenSans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  "sans-serif",
].join();

export const GlobalFontStyle = createGlobalStyle`
  html {
    font-family: ${FONT_FAMILY};
  }

  body{
    margin: 0;
  }
`;
export const GlobalStyle = () => (
  <>
    <GlobalFontStyle />
    <DocumentStyle />
    <PlasmaStyle />
  </>
);
