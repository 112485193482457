import { DropdownNode } from "@salutejs/plasma-web/components/Dropdown/Dropdown.types";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Header } from "../components/Header";
import { Icon } from "../components/Icon";
import { InfoPanel } from "../components/InfoPanel";

import { ModelPly } from "../components/ModelPly/ModelPly";
import { ModelPlyThree } from "../components/ModelPlyThree";

import { Wrapper } from "../components/PageWrapper";
import { ModelNotFound } from "../components/ModelNotFound";
import { WidgetModal } from "../components/WidgetModal";
import { axiosCdnBaseUrl, axiosScene } from "../utils/axios";
import { SIZES } from "../utils/breakpoints";
import { adapterModelPly, downloadModel } from "../utils/libs";
import { ModelParams, ModelPlyI } from "../utils/types";

const DEFAULT_VIEWER_PARAMS: ModelParams = {
  cameraUp: [0, -1, -0.17],
  initialCameraPos: [-5, -1, -1],
  initialCameraLookAt: [2, 0, 0],
  cameraSpecs: {
    fy: 1664.983096464509,
    fx: 1664.9830964645084,
    near: 0.1,
    far: 500,
  },
  plyPath: "/kerher.ply",
  // plyPath: "/sculpture.ply",
  // plyPath: axiosCdnBaseUrl + `ply/sculpture.ply`,
};

const DEFAULT_MODEL_PLY: ModelPlyI = {
  modelParams: DEFAULT_VIEWER_PARAMS,
  nickname: "Jeffrey Coffey",
  uid: "1234",
  description: "model ply",
  path_obj_pre_phone: "ply/kerher.ply",
  path_gltf: "",
  date: "2023-10-11 23:45:32.898593",
};

const ITEM_DROPDOWN_VALUE = {
  copy: "copy",
  download: "download",
};

const StyledPageWrapper = styled(Wrapper)<{ isFullScreenModel: boolean }>`
  display: flex;
  padding-top: 0;
  grid-template: none;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  > div {
    &:first-child {
      margin-top: ${({ isFullScreenModel }) =>
        isFullScreenModel ? "0" : "28px"};
      position: relative;
    }
  }

  @media (max-width: ${SIZES.sm}px) {
    flex-direction: column;

    > div {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const ModelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${SIZES.sm}px) {
    height: 100%;
  }
`;

export const Ply: FC = () => {
  const { uid } = useParams<{ uid?: string }>();

  const [isFullScreenModel, setIsFullScreenModel] = useState<boolean>(false);
  const [model, setModel] = useState<ModelPlyI>();
  const [error, setError] = useState<string>("");

  const dropdownItems = useMemo<DropdownNode[]>(() => {
    return [
      {
        value: ITEM_DROPDOWN_VALUE.copy,
        label: "Копировать виджет",
        contentLeft: <Icon name="copy_line_24" />,
      },
      {
        value: ITEM_DROPDOWN_VALUE.download,
        label: "Скачать",
        contentLeft: <Icon name="cloud_download_24" />,
      },
    ];
  }, []);

  const onItemSelect = useCallback((item: DropdownNode) => {
    switch (item.value) {
      case ITEM_DROPDOWN_VALUE.download:
        downloadModel("", "");
        break;
    }
  }, []);

  const getModel = async (uid?: string) => {
    if (!uid) {
      // setModel(DEFAULT_MODEL_PLY);
      setError("error");

      return;
    }

    try {
      const { data } = (await axiosScene.get(`/ply/model/${uid}`)) as {
        data: ModelPlyI[];
      };
      if (!data?.[0]) {
        return;
      }

      setModel(adapterModelPly(data[0]));
    } catch (error) {
      // setModel(DEFAULT_MODEL_PLY);
      setError("error");
    }
  };

  useEffect(() => {
    getModel(uid);
  }, [uid]);

  return (
    <StyledPageWrapper isFullScreenModel={isFullScreenModel}>
      {!isFullScreenModel && (
        <div>
          <Header
            // closeSrc={isMobile && back ? String(back) : undefined}
            dropdownItems={dropdownItems}
            onItemSelect={onItemSelect}
          />
          {/* <WidgetModal
            uid={model?.uid}
            isOpenWidgetModal={isOpenWidgetModal}
            closeWidgetModal={closeWidgetModal}
          /> */}
          <InfoPanel model={model} />
        </div>
      )}

      <ModelWrapper>
        {/* <ModelPly /> */}
        {model?.modelParams && (
          <ModelPlyThree modelParams={model?.modelParams} />
        )}
      </ModelWrapper>

      {error && <ModelNotFound />}
    </StyledPageWrapper>
  );
};
