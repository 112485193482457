import { FC, useEffect, useRef } from "react";
import styled from "styled-components/macro";

import { IModel } from "../../utils/types";

import { BrokenLine } from "../BrokenLine";
import { SidePanel } from "../SidePanel";

import { PropsAnimate } from "./types";
import { scrollTarget, transition } from "./constants";
import {
  generateStyleAnimate,
  pxToPercentHeight,
  pxToPercentWidth,
} from "./utils";

const positionsWrapper = [
  //0

  {
    left: 428,
    top: 400,
    opacity: 0,
  },
  //1

  {
    left: 428,
    top: 206,
    opacity: 1,
  },

  //2
  {
    left: 428,
    top: 0,
    opacity: 1,
  },

  //3
  {
    left: 428,
    top: 0,
    opacity: 1,
  },
];

const positionsSidePanel = [
  //0

  {
    top: 400,
    opacity: 0,
  },

  //1
  {
    top: 88,
    opacity: 1,
  },
];

type PositionWrapper = typeof positionsWrapper[0];
type PositionSidePanel = typeof positionsSidePanel[0];

interface PropsWrapper extends PropsAnimate {
  scrollTop: number;
}

const WrapperGrid = styled.div<PropsWrapper>`
  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionWrapper>(
      positionsWrapper[currentAnimate - 4] ||
        positionsWrapper[
          currentAnimate - 4 > 0 ? positionsWrapper.length - 1 : 0
        ]
    )};

  ${({ currentAnimate }) =>
    currentAnimate >= 6
      ? `overflow: auto; height:${pxToPercentHeight(722)};`
      : ""}

  position: absolute;
  width: ${pxToPercentWidth(706)};
  box-sizing: border-box;
  z-index: 11;
  padding-top: ${pxToPercentHeight(88)};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const WrapperSidePanel = styled.div<PropsAnimate>`
  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionSidePanel>(
      positionsSidePanel[currentAnimate - 5] ||
        positionsSidePanel[
          currentAnimate - 5 > 0 ? positionsSidePanel.length - 1 : 0
        ]
    )};

  position: absolute;
  width: ${pxToPercentWidth(346)};
  left: ${pxToPercentWidth(64)};
  box-sizing: border-box;
  z-index: 11;
`;

interface Props extends PropsAnimate {
  showModels: IModel[];
  scrollTop: number;
}

export const Grid: FC<Props> = ({ currentAnimate, showModels, scrollTop }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && scrollTop >= scrollTarget[6]) {
      ref.current.scrollTo(0, scrollTop - scrollTarget[6]);
    }
  }, [scrollTop]);

  if (currentAnimate < 4) {
    return null;
  }

  return (
    <>
      <WrapperGrid
        ref={ref}
        scrollTop={scrollTop}
        currentAnimate={currentAnimate}
      >
        <BrokenLine models={showModels} />
      </WrapperGrid>

      <WrapperSidePanel currentAnimate={currentAnimate}>
        <SidePanel isLabel={false} />
      </WrapperSidePanel>
    </>
  );
};
