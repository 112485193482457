import { SIZES } from "../../utils/breakpoints";

const PLACES_1200 = {
  quantityItem: 11,
  rowSize: 55,
  positions: [
    //1
    {
      "grid-row-start": 1,
      "grid-column-start": 4,
      "grid-row-end": 10,
      "grid-column-end": 11,
    },

    //2
    {
      "grid-row-start": 4,
      "grid-column-start": 11,
      "grid-row-end": 19,
      "grid-column-end": 21,
    },

    //3
    {
      "grid-row-start": 10,
      "grid-column-start": 1,
      "grid-row-end": 17,
      "grid-column-end": 6,
    },

    //4
    {
      "grid-row-start": 10,
      "grid-column-start": 6,
      "grid-row-end": 17,
      "grid-column-end": 11,
    },

    //5
    {
      "grid-row-start": 19,
      "grid-column-start": 11,
      "grid-row-end": 26,
      "grid-column-end": 16,
    },

    //6
    {
      "grid-row-start": 17,
      "grid-column-start": 4,
      "grid-row-end": 26,
      "grid-column-end": 11,
    },

    //7
    {
      "grid-row-start": 26,
      "grid-column-start": 1,
      "grid-row-end": 41,
      "grid-column-end": 11,
    },

    //8
    {
      "grid-row-start": 26,
      "grid-column-start": 11,
      "grid-row-end": 35,
      "grid-column-end": 18,
    },

    //9
    {
      "grid-row-start": 41,
      "grid-column-start": 4,
      "grid-row-end": 56,
      "grid-column-end": 14,
    },

    //10
    {
      "grid-row-start": 41,
      "grid-column-start": 14,
      "grid-row-end": 48,
      "grid-column-end": 19,
    },

    //11
    {
      "grid-row-start": 48,
      "grid-column-start": 14,
      "grid-row-end": 55,
      "grid-column-end": 19,
    },
  ],
};

const PLACES_960 = {
  quantityItem: 10,
  rowSize: 66,
  positions: [
    //1
    {
      "grid-row-start": 1,
      "grid-column-start": 1,
      "grid-row-end": 12,
      "grid-column-end": 9,
    },

    //2
    {
      "grid-row-start": 8,
      "grid-column-start": 9,
      "grid-row-end": 23,
      "grid-column-end": 21,
    },

    //3
    {
      "grid-row-start": 12,
      "grid-column-start": 3,
      "grid-row-end": 20,
      "grid-column-end": 9,
    },

    //4
    {
      "grid-row-start": 20,
      "grid-column-start": 1,
      "grid-row-end": 31,
      "grid-column-end": 9,
    },

    //5
    {
      "grid-row-start": 23,
      "grid-column-start": 9,
      "grid-row-end": 31,
      "grid-column-end": 15,
    },

    //6
    {
      "grid-row-start": 31,
      "grid-column-start": 1,
      "grid-row-end": 49,
      "grid-column-end": 14,
    },

    //7
    {
      "grid-row-start": 31,
      "grid-column-start": 14,
      "grid-row-end": 39,
      "grid-column-end": 20,
    },

    //8
    {
      "grid-row-start": 49,
      "grid-column-start": 2,
      "grid-row-end": 57,
      "grid-column-end": 8,
    },

    //9
    {
      "grid-row-start": 57,
      "grid-column-start": 2,
      "grid-row-end": 65,
      "grid-column-end": 8,
    },

    //10
    {
      "grid-row-start": 49,
      "grid-column-start": 8,
      "grid-row-end": 67,
      "grid-column-end": 21,
    },
  ],
};

export const generatePlace = (i: number, width: number): string => {
  let places = PLACES_960;

  if (width > SIZES.lg) {
    places = PLACES_1200;
  }

  const { positions, quantityItem, rowSize } = places;
  const ceil = Math.floor(i / quantityItem);

  const sizes = { ...positions[i % quantityItem] };

  sizes["grid-row-start"] = sizes["grid-row-start"] + rowSize * ceil;
  sizes["grid-row-end"] = sizes["grid-row-end"] + rowSize * ceil;

  return Object.entries(sizes).reduce(
    (acc, [key, value]) => `${acc}${key}:${value};`,
    ""
  );
};
