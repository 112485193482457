import { PlyParser } from "./PlyParser";
import { SplatBuffer } from "./SplatBuffer";

export class PlyLoader {
  splatBuffer: SplatBuffer | null;
  constructor() {
    this.splatBuffer = null;
  }

  fetchFile(fileName: string): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      fetch(fileName)
        .then((res) => {
          return res.arrayBuffer();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadFromFile(fileName: string) {
    return new Promise((resolve, reject) => {
      const loadPromise = this.fetchFile(fileName);
      loadPromise
        .then((plyFileData: ArrayBuffer) => {
          const plyParser = new PlyParser(plyFileData);
          const splatBuffer = plyParser.parseToSplatBuffer();
          this.splatBuffer = splatBuffer;
          resolve(splatBuffer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
