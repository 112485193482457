import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components/macro";

import { BodyS, Button, Dropdown } from "@salutejs/plasma-b2c";
import {
  DropdownNode,
  OnItemSelect,
} from "@salutejs/plasma-web/components/Dropdown/Dropdown.types";

import { useWindowSize } from "../../utils/hooks";
import { SIZES } from "../../utils/breakpoints";

import { Icon } from "../Icon";

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  grid-area: head;
`;

const StyledDropdown = styled(Dropdown)`
  z-index: 2;

  > div {
    &:last-child {
      border-radius: 10px;
      overflow: hidden;
      position: fixed;
      top: 82px;
      width: calc(100% - 32px);
      left: 16px;
    }
  }
`;

const Close = styled(Button)`
  width: auto;
  justify-content: flex-start;
`;

const CloseText = styled(BodyS)`
  width: auto;
  justify-content: flex-start;
`;

const Logo = styled(Button)`
  justify-content: flex-start;
  width: 172px;
  padding-left: 0;

  svg {
    fill: white;
  }

  @media (max-width: ${SIZES.sm}px) {
    padding-left: 0;
  }
`;

const Burger = styled(Button)`
  width: auto;
  margin-right: 8px;
`;

interface Props {
  closeSrc?: string | -1;
  dropdownItems?: DropdownNode[];
  onItemSelect?: OnItemSelect;
}

export const Header: FC<Props> = memo(
  ({ closeSrc, dropdownItems, onItemSelect }) => {
    const navigate = useNavigate();

    const { isMobile } = useWindowSize();

    return (
      <Wrapper>
        <Logo
          view="clear"
          text={<Icon name="brand_logo" />}
          onClick={() => navigate("/")}
        />

        <div>
          {isMobile && dropdownItems && (
            <StyledDropdown
              trigger="click"
              placement="bottom"
              items={dropdownItems}
              onItemSelect={onItemSelect}
            >
              <Burger
                view="secondary"
                size="s"
                contentRight={<Icon name="three_dots_24" />}
              />
            </StyledDropdown>
          )}

          {closeSrc && (
            <Close
              view="secondary"
              size="s"
              text={isMobile ? null : <CloseText>Закрыть</CloseText>}
              contentRight={<Icon name="close_24_line" />}
              onClick={() => navigate(-1)}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);
