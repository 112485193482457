import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { IModel } from "./types";

export interface StateType {
  models: IModel[];
  setModels: React.Dispatch<React.SetStateAction<IModel[]>>;

  filteredModels: IModel[];
  setFilteredModels: React.Dispatch<React.SetStateAction<IModel[]>>;

  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;

  isFullScreenModel: boolean;
  setIsFullScreenModel: React.Dispatch<React.SetStateAction<boolean>>;

  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StateContext = createContext<StateType>({
  models: [],
  setModels: () => {},

  filteredModels: [],
  setFilteredModels: () => {},

  searchQuery: "",
  setSearchQuery: () => {},

  isFullScreenModel: false,
  setIsFullScreenModel: () => {},

  isLoading: false,
  setIsLoading: () => {},
});

interface Props {
  children: ReactNode;
}

export const StateProvider: FC<Props> = ({ children }) => {
  const [models, setModels] = useState<IModel[]>([]);
  const [filteredModels, setFilteredModels] = useState<IModel[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFullScreenModel, setIsFullScreenModel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setFilteredModels(models);
  }, [models]);

  return (
    <StateContext.Provider
      value={{
        models,
        setModels,
        filteredModels,
        setFilteredModels,
        searchQuery,
        setSearchQuery,
        isFullScreenModel,
        setIsFullScreenModel,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
