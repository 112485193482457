import { FC, memo } from "react";
import styled from "styled-components/macro";
import { H1, Button, Body2, H2 } from "@salutejs/plasma-b2c";

import sceneFrame from "../../assets/scene-frame.png";
import { blackPrimary } from "@salutejs/plasma-tokens-b2c";

import { Icon } from "../Icon";
import { PropsAnimate } from "./types";
import { transition, widthOrHeightInitial } from "./constants";
import { generateStyleAnimate, pxToPercentHeight } from "./utils";

const positionsPhone = [
  //0
  {
    width: 256,
    height: 477,
    "aspect-ratio": "256/477",
    left: 652,
    top: 219,
    opacity: 0,
    background: "none",
    border: "3px solid #3f81fd",
    "z-index": "11",
    "border-bottom-right-radius": "32",
  },

  //1
  {
    width: 256,
    height: 477,
    "aspect-ratio": "256/477",
    left: 792,
    top: 219,
    opacity: 1,
    background: "none",
    border: "3px solid #3f81fd",
    "z-index": "11",
    "border-bottom-right-radius": "32",
  },

  //2
  {
    width: 256,
    height: 477,
    "aspect-ratio": "256/477",
    left: 472,
    top: 193,
    opacity: 1,
    background: "none",
    border: "3px solid #3f81fd",
    "z-index": "11",
    "border-bottom-right-radius": "32",
  },

  //2
  {
    width: 302,
    height: 464,
    "aspect-ratio": "302/464",
    left: 669,
    top: 146,
    opacity: 1,
    background: "#dee9ff",
    border: "3px solid #3f81fd",
    "z-index": "9",
    "border-bottom-right-radius": "0",
  },

  //2
  {
    width: 255,
    height: 354,
    "aspect-ratio": "255/354",
    left: 517,
    top: 166,
    opacity: 1,
    background: "white",
    border: "none",
    "z-index": "9",
    "border-bottom-right-radius": "22",
  },

  {
    width: 230,
    height: 320,
    "aspect-ratio": "230/320",
    left: 542,
    top: 206,
    opacity: 0,
    background: "white",
    border: "none",
    "z-index": "9",
    "border-bottom-right-radius": 20,
  },
];

const positionsPlay = [
  //0
  {
    width: 50,
    top: 219,
    left: 895,
    opacity: 0,
    background: "red",
  },

  //1
  {
    width: 50,
    top: 603,
    left: 895,
    opacity: 1,
    background: "red",
  },

  //2
  {
    width: 50,
    top: 577,
    left: 575,
    opacity: 1,
    background: "red",
  },

  //3
  {
    width: 92,
    top: 382,
    left: 774,
    opacity: 1,
    background: "none",
  },

  //4
  {
    width: 92,
    top: 200,
    left: 774,
    opacity: 0,
    background: "none",
  },
];

const positionsPlayIcon = [
  //0
  {
    opacity: 0,
  },

  //1
  {
    opacity: 0,
  },

  //2
  {
    opacity: 0,
  },

  //2
  {
    opacity: 1,
  },

  //2
  {
    opacity: 0,
  },
];

const positionsTextBlockMain = [
  //0
  {
    width: 346,
    left: 64,
    top: 441,
    opacity: 0,
  },

  //1
  {
    width: 346,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //2
  {
    width: 346,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //3
  {
    width: 346,
    left: 64,
    top: 36,
    opacity: 0,
  },
];

const positionsTextBlockRight = [
  //0
  {
    width: 316,
    left: 985,
    top: 279,
    opacity: 0,
  },

  //1
  {
    width: 316,
    left: 805,
    top: 249,
    opacity: 1,
  },

  //2
  {
    width: 316,
    left: 805,
    top: 141,
    opacity: 0,
  },
];

const positionsBackgroundMain = [
  //0
  {
    width: 1123,
    height: 530,
    left: 38,
    top: 120,
    opacity: 0,
  },

  //1
  {
    width: 1123,
    height: 530,
    left: 38,
    top: 120,
    opacity: 1,
  },

  //2
  {
    width: 1123,
    height: 530,
    left: 38,
    top: 120,
    opacity: 1,
  },

  //3
  {
    width: 1123,
    height: 530,
    left: 38,
    top: 120,
    opacity: 0,
  },
];

type PositionPhone = typeof positionsPhone[0];
type PositionPlay = typeof positionsPlay[0];
type PositionPlayIcon = typeof positionsPlayIcon[0];
type PositionTextBlockMain = typeof positionsTextBlockMain[0];
type PositionTextBlockRight = typeof positionsTextBlockRight[0];
type PositionBackgroundMain = typeof positionsBackgroundMain[0];

const Phone = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 9;
  border-radius: 32px;
  border: 3px solid #3f81fd;
  box-sizing: border-box;
  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionPhone>(
      positionsPhone[currentAnimate] ||
        positionsPhone[positionsPhone.length - 1]
    )};
  ${widthOrHeightInitial}
`;

const Play = styled.div<PropsAnimate>`
  position: absolute;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  z-index: 13;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionPlay>(
      positionsPlay[currentAnimate] || positionsPlay[positionsPlay.length - 1]
    )};

  svg {
    ${transition}
    ${({ currentAnimate }) =>
      generateStyleAnimate<PositionPlayIcon>(
        positionsPlayIcon[currentAnimate] ||
          positionsPlayIcon[positionsPlay.length - 1]
      )};
  }
`;

const TextBlockMain = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;
  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockMain>(
      positionsTextBlockMain[currentAnimate] ||
        positionsTextBlockMain[positionsTextBlockMain.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${H1} {
    margin-bottom: ${pxToPercentHeight(20)};
    font-size: 64px;
    line-height: 68px;

    color: ${blackPrimary};
  }

  ${H2} {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: ${blackPrimary};
  }

  > button {
    padding: 0;
    width: fit-content;
    margin-top: 20px;
  }
`;

const TextBlockRight = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockRight>(
      positionsTextBlockRight[currentAnimate - 1] ||
        positionsTextBlockRight[positionsTextBlockRight.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${Body2} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    color: ${blackPrimary};

    text-align: left;
  }

  > button {
    padding: 0;
    width: fit-content;
    margin-bottom: 48px;
  }

  > div {
    display: flex;
    gap: 16px;
  }

  > div:first-child {
    margin-bottom: 48px;
  }
`;

const BackgroundMain = styled.div<PropsAnimate>`
  position: absolute;
  aspect-ratio: 1123/530;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionBackgroundMain>(
      positionsBackgroundMain[currentAnimate] ||
        positionsBackgroundMain[positionsBackgroundMain.length - 1]
    )};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const JustCreateVideo: FC<PropsAnimate> = memo(({ currentAnimate }) => {
  if (currentAnimate < 1) {
    return null;
  }

  return (
    <>
      <Phone currentAnimate={currentAnimate}></Phone>
      <Play currentAnimate={currentAnimate}>
        <Icon name="play" />
      </Play>

      <TextBlockMain currentAnimate={currentAnimate}>
        <H1>Просто снять видео</H1>

        <H2>
          Сними видео вокруг предмета по инструкции, чтобы получить модель
        </H2>

        <Button
          view="clear"
          contentLeft={
            <Icon name="arrow_diag_24_line" svgProps={{ fill: blackPrimary }} />
          }
          text={<H2>Инструкция</H2>}
        />
      </TextBlockMain>

      <TextBlockRight currentAnimate={currentAnimate}>
        <div>
          <Icon name="video_cam_re_24_line" size={48} />
          <Body2>
            Система может <br />
            по видеоряду воссоздать <br />
            3D модель
          </Body2>
        </div>

        <div>
          <Icon name="post_24_line" size={48} />
          <Body2>
            Подойдёт для съемки <br />
            товаров для интернет- <br />
            магазинов
          </Body2>
        </div>
      </TextBlockRight>

      <BackgroundMain currentAnimate={currentAnimate}>
        <img src={sceneFrame} alt="" />
      </BackgroundMain>
    </>
  );
});
