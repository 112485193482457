export const pxToPercentWidth = (px: number, fullSize: number = 1199) => {
  return `${(px * 100) / fullSize}vw`;
};

export const pxToPercentHeight = (px: number, fullSize: number = 800) => {
  return `${(px * 100) / fullSize}vh`;
};

export const generateStyleAnimate = <
  Position extends { [key: string]: string | number }
>(
  position: Position
): string => {
  const fromHeight = ["height", "top"];
  const exceptions = ["opacity"];

  return Object.entries(position).reduce(
    (acc, [key, value]) =>
      `${acc} ${key}:${
        typeof value === "string" || exceptions.includes(key)
          ? value
          : fromHeight.includes(key)
          ? pxToPercentHeight(value)
          : pxToPercentWidth(value)
      };`,
    ""
  );
};
