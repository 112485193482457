import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";

import { SettingsPanel } from "../components/SettingsPanel";
import { ShadersModal } from "../components/ShadersModal";
import { HelpPositionWrapper } from "../components/Model";

import { createViewDependenceFunctions, init } from "../utils/MobileNerf";
import { Paths, Shaders } from "../utils/types";
import { handlePaths } from "../utils/pathHandler";
import { useModal } from "../utils/hooks";
import { render_vert, gbuffer_vert, gbuffer_frag } from "../utils/shaders";
import { ShaderVersions } from "../utils/constants";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const DebuggingModelPage: FC = () => {
  const [paths, setPaths] = useState<Paths>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shaders, setShaders] = useState<Shaders>({
    render_vert,
    gbuffer_vert,
    gbuffer_frag,
  });
  const [json, setJson] = useState<{ [key: string]: any }>();

  const [files, setFiles] = useState<FileList>();

  const [isOpenShadersModal, openShadersModal, closeShadersModal] = useModal();

  const handleFileChange = ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>) => {
    if (files) {
      setFiles(files);
    }
  };

  const container = useRef<HTMLDivElement>(null);
  const containerHelpPosition = useRef<HTMLDivElement>(null);
  const configScene = useRef<{ [key: string]: any }>({});
  const wrapper = useRef<HTMLDivElement>(null);

  const getJson = async (url: string) => {
    const res = await fetch(url);
    const json = await res.json();
    setJson(json);
  };

  const createFragmentShader = (network_weights: { [key: string]: any }) => {
    let shaderVersion = ShaderVersions.first;
    if (
      network_weights.hasOwnProperty("0_weights") &&
      network_weights.hasOwnProperty("1_weights") &&
      network_weights.hasOwnProperty("2_weights")
    ) {
      shaderVersion = ShaderVersions.first;
      console.log("Old version. ALl 3 weights exists");
    } else {
      shaderVersion = ShaderVersions.second;
      console.log("New version with smaller 2 layer mlp");
    }
    const fragmentShaderSource = createViewDependenceFunctions(
      network_weights,
      shaderVersion
    );

    setShaders((prev) => ({ ...prev, fragmentShaderSource }));
  };

  const getPaths = async (files: FileList) => {
    const arr = Array.from(files);

    const pathUrls = handlePaths(Array.from(arr));

    setPaths(pathUrls);

    setIsLoading(false);

    getJson(pathUrls.mlpJson);
  };

  const toggleAutoRotate = useCallback(() => {
    configScene.current.autoRotate = !configScene.current.autoRotate;
  }, []);

  useEffect(() => {
    if (!paths) {
      return;
    }
    const config: any = init({
      paths,
      container: container.current,
      containerHelpPosition: containerHelpPosition.current,
      wrapper: wrapper.current,
      ...shaders,
    });

    config.isStopRender = false;

    configScene.current = config;

    return () => {
      config.isStopRender = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      container.current?.replaceChildren();
    };
  }, [paths, shaders]);

  useEffect(() => {
    if (json) {
      createFragmentShader(json);
    }
  }, [json]);

  useEffect(() => {
    if (files) {
      getPaths(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <Wrapper ref={wrapper}>
      {isLoading && (
        <input
          multiple
          type="file"
          onChange={handleFileChange}
          style={{
            color: "white",
            position: "absolute",
            top: "50%",
            cursor: "pointer",
          }}
        />
      )}

      {!isLoading && (
        <SettingsPanel
          openShadersModal={openShadersModal}
          toggleAutoRotate={toggleAutoRotate}
        />
      )}

      <ShadersModal
        isOpenShadersModal={isOpenShadersModal}
        closeShadersModal={closeShadersModal}
        shaders={shaders}
        setShaders={setShaders}
      />

      <div
        ref={container}
        style={{ width: "99vw", height: "calc(100vh - 90px )" }}
      />

      <HelpPositionWrapper ref={containerHelpPosition} />
    </Wrapper>
  );
};
