import { FC } from "react";
import styled from "styled-components/macro";
import { Body1, H1, Button, Body2 } from "@salutejs/plasma-b2c";

import { Icon } from "../Icon";

import { PropsAnimate } from "./types";
import { transition } from "./constants";
import { generateStyleAnimate, pxToPercentHeight } from "./utils";

const positionsTextBlockMain = [
  //0
  {
    width: 437,
    left: 64,
    top: 441,
    opacity: 0,
  },

  //1
  {
    width: 437,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //2
  {
    width: 346,
    left: 64,
    top: 36,
    opacity: 0,
  },
];

const positionsTextBlockRight = [
  //0
  {
    width: 316,
    left: 805,
    top: 219,
    opacity: 0,
  },

  //1
  {
    width: 316,
    left: 805,
    top: 189,
    opacity: 1,
  },

  //2
  {
    width: 316,
    left: 805,
    top: 189,
    opacity: 0,
  },
];

const positionsBackgroundMain = [
  //0
  {
    width: 1123,
    left: 38,
    top: 400,
    opacity: 0,
  },

  //1
  {
    width: 1123,
    left: 38,
    top: 130,
    opacity: 1,
  },

  //1
  {
    width: 1123,
    left: 38,
    top: 130,
    opacity: 0,
  },
];

type PositionTextBlockMain = typeof positionsTextBlockMain[0];
type PositionTextBlockRight = typeof positionsTextBlockRight[0];
type PositionBackgroundMain = typeof positionsBackgroundMain[0];

const TextBlockMain = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockMain>(
      positionsTextBlockMain[currentAnimate - 3] ||
        positionsTextBlockMain[positionsTextBlockMain.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${H1} {
    margin-bottom: ${pxToPercentHeight(20)};
    font-size: 64px;
    line-height: 68px;
  }

  ${Body1} {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  > button {
    padding: 0;
    width: fit-content;
  }
`;

const TextBlockRight = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockRight>(
      positionsTextBlockRight[currentAnimate - 3] ||
        positionsTextBlockRight[positionsTextBlockRight.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${Body2} {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    text-align: left;
  }

  > button {
    padding: 0;
    width: fit-content;
    margin-bottom: 48px;
  }

  > div {
    display: flex;
    gap: 12px;
  }

  > div:first-child {
    margin-bottom: 48px;
  }
`;

const BackgroundMain = styled.div<PropsAnimate>`
  position: absolute;
  background: black;
  z-index: 1;
  aspect-ratio: 1123/440;
  border-radius: 56px;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionBackgroundMain>(
      positionsBackgroundMain[currentAnimate - 3] ||
        positionsBackgroundMain[positionsBackgroundMain.length - 1]
    )};

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const Integration: FC<PropsAnimate> = ({ currentAnimate }) => {
  return (
    <>
      <TextBlockMain currentAnimate={currentAnimate}>
        <H1>Интеграция</H1>

        <Body1>
          Просто снимите видео вокруг объекта, отправьте его в телеграм-бота
          и получите 3d модель
        </Body1>

        <Button
          view="clear"
          contentLeft={<Icon name="arrow_diag_24_line" />}
          text={<Body1>Напишите нам</Body1>}
        />

        <Button
          view="clear"
          contentLeft={<Icon name="mail_24_line" />}
          text={<Body1>examplemail@exmail.mail</Body1>}
        />
      </TextBlockMain>

      <TextBlockRight currentAnimate={currentAnimate}>
        <div>
          <Icon
            name="video_cam_re_24_line"
            size={48}
            svgProps={{ fill: "white" }}
          />
          <Body2>
            Система может <br />
            по видеоряду воссоздать <br />
            3D модель
          </Body2>
        </div>

        <div>
          <Icon name="post_24_line" size={48} svgProps={{ fill: "white" }} />
          <Body2>
            Подойдёт для съемки <br />
            товаров для интернет- <br />
            магазинов
          </Body2>
        </div>
      </TextBlockRight>

      <BackgroundMain currentAnimate={currentAnimate} />
    </>
  );
};
