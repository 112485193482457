import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DropdownNode } from "@salutejs/plasma-web/components/Dropdown/Dropdown.types";

import { Icon } from "../components/Icon";

import { axiosScene } from "./axios";
import { SIZES } from "./breakpoints";
import {
  MIN_DISTANCE_ON_BOTTOM_PAGE_FOR_REQ,
  MODELS_QUANTITY_ON_REQUEST,
} from "./constants";
import { useStateContext } from "./context";
import { chunking } from "./libs";
import { IModel } from "./types";

export const useModal = (
  initialValue = false
): [boolean, () => void, () => void] => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(initialValue);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return [isOpenModal, openModal, closeModal];
};

export const useScrollPageEnd = (
  callback: () => void,
  distance: number
): void => {
  const scrollHandler = useCallback(() => {
    const { scrollHeight, scrollTop } = document.documentElement;
    const isPageEnd =
      scrollHeight - (scrollTop + window.innerHeight) < distance;

    if (isPageEnd) {
      callback();
    }
  }, [callback, distance]);

  useEffect(() => {
    if (document.body.offsetHeight < window.innerHeight) {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);
};

export const useLazyModels = (
  urls: string,
  firstModelUid?: string
): IModel[] => {
  const { filteredModels, setModels, setIsLoading } = useStateContext();

  const [chunkModels, setChunkModels] = useState<IModel[][]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showModels, setShowModels] = useState<IModel[]>([]);

  const { width, height, isMobile } = useWindowSize();

  const quantity = useMemo(() => {
    if (isMobile) {
      return MODELS_QUANTITY_ON_REQUEST;
    }

    const cardsOnWidth = Math.ceil(width / 250);
    const cardsOnHeight = Math.ceil(height / 330);
    return cardsOnWidth * cardsOnHeight || MODELS_QUANTITY_ON_REQUEST;
  }, [width, height, isMobile]);

  const getModels = async () => {
    setIsLoading(true);

    const { data } = await axiosScene.get<IModel[]>(urls);

    if (firstModelUid) {
      const newData: IModel[] = [];
      data.forEach((model) => {
        if (model.uid === firstModelUid) {
          newData.unshift(model);
          return;
        }

        newData.push(model);
      });

      setModels(newData);
    } else {
      setModels(data);
    }

    setIsLoading(false);
  };

  const handlerEnd = useCallback(() => {
    if (totalCount > currentPage) {
      setFetching(true);
    }
  }, [totalCount, currentPage]);

  useScrollPageEnd(handlerEnd, MIN_DISTANCE_ON_BOTTOM_PAGE_FOR_REQ);

  useEffect(() => {
    if (fetching && chunkModels.length > 0) {
      setShowModels((prev) => {
        const currentModels = chunkModels[currentPage] || [];
        return [...prev, ...currentModels];
      });

      setCurrentPage((prev) => prev + 1);
      setFetching(false);
    }
  }, [fetching, chunkModels, currentPage, setIsLoading]);

  useEffect(() => {
    if (filteredModels) {
      const allChunkingModels = chunking(filteredModels, quantity);
      setChunkModels(allChunkingModels);
      setTotalCount(allChunkingModels.length);

      setFetching(true);
      setShowModels([]);
      setCurrentPage(0);
    }
  }, [filteredModels, quantity]);

  useEffect(() => {
    getModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showModels;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
    isMobile: boolean;
    SIZES: { [key in keyof typeof SIZES]: number };
  }>({
    width: 0,
    height: 0,
    isMobile: false,
    SIZES,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < SIZES.sm,
        SIZES,
      });
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

const ITEM_DROPDOWN_VALUE = {
  allModels: "allModels",
  category: "category",
  instruction: "instruction",
  aboutService: "aboutService",
};

export const useDropDownCardsPage = () => {
  const navigate = useNavigate();

  const onItemSelect = useCallback(
    (item: DropdownNode) => {
      switch (item.value) {
        case ITEM_DROPDOWN_VALUE.allModels:
          navigate(`/all`);
          break;
      }
    },
    [navigate]
  );

  const dropdownItems = useMemo<DropdownNode[]>(() => {
    return [
      {
        value: ITEM_DROPDOWN_VALUE.allModels,
        label: "Все модели",
        contentLeft: <Icon name="copy_line_24" />,
      },
      {
        value: ITEM_DROPDOWN_VALUE.instruction,
        label: "Инструкция",
        contentLeft: <Icon name="copy_line_24" />,
      },
      {
        value: ITEM_DROPDOWN_VALUE.aboutService,
        label: "О сервисе",
        contentLeft: <Icon name="copy_line_24" />,
      },
    ];
  }, []);

  return { dropdownItems, onItemSelect };
};
