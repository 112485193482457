import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { H1 } from "@salutejs/plasma-b2c";

import { IModel, Paths } from "../../utils/types";

import { init } from "../../utils/MobileNerf";
import { unpack } from "../../utils/unpack";

const StyledModel = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? "initial" : "none")};
`;

interface Props {
  model: IModel;
  isShow: boolean;
  wrapper: React.RefObject<HTMLDivElement>;
}

export const Model: FC<Props> = ({ model, isShow, wrapper }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [extractedFile, setExtractedFile] = useState<boolean>(false);
  const [paths, setPaths] = useState<Paths>();

  const container = useRef<HTMLDivElement>(null);
  const configScene = useRef<{ [key: string]: any }>({});

  const getPaths = async (abortController: AbortController) => {
    if (!model) {
      return;
    }

    try {
      const pathsData = await unpack({
        url: model.path_obj_pre_phone,
        setProgress,
        setExtractedFile,
        abortController,
      });

      setPaths(pathsData);

      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (model) {
      getPaths(abortController);
    }

    return () => {
      abortController.abort();
      if (model && container.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const div = container.current;
        while (div.firstChild) {
          div.removeChild(div.firstChild);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (!paths) {
      return;
    }

    const config: any = init({
      paths,
      container: container.current,
      containerHelpPosition: undefined,
      wrapper: wrapper.current,
    });

    config.isStopRender = false;
    configScene.current = config;

    return () => {
      config.isStopRender = true;
    };
  }, [paths]);

  return (
    <>
      {progress && progress !== 100 && <H1>{`${progress}%`}</H1>}

      <StyledModel ref={container} isShow={isShow} />
    </>
  );
};
