import { FC } from "react";
import styled from "styled-components/macro";
import { Body1, H1 } from "@salutejs/plasma-b2c";

import { PropsAnimate } from "./types";
import { transition, widthOrHeightInitial } from "./constants";
import { generateStyleAnimate, pxToPercentHeight } from "./utils";

const positionsPhone = [
  //0
  {
    width: 302,
    height: 464,
    "aspect-ratio": "302/464",
    left: 669,
    top: 146,
    opacity: 0,
    "border-bottom-right-radius": 22,
  },

  //1
  {
    width: 255,
    height: 354,

    "aspect-ratio": "255/354",
    left: 517,
    top: 166,
    opacity: 1,
    "border-bottom-right-radius": 22,
  },

  //2
  {
    width: 230,
    height: 320,

    "aspect-ratio": "230/320",
    left: 542,
    top: 206,
    opacity: 0,
    "border-bottom-right-radius": 20,
  },
];

const positionsTextBlockMain = [
  //0
  {
    width: 437,
    left: 64,
    top: 300,
    opacity: 0,
  },

  //1
  {
    width: 437,
    left: 64,
    top: 172,
    opacity: 1,
  },

  //2
  {
    width: 346,
    left: 64,
    top: 80,
    opacity: 0,
  },
];

const positionsBackgroundMain = [
  //0
  {
    width: 1123,
    height: 440,
    left: 38,
    top: 130,
    opacity: 0,
    "border-radius": 56,
  },

  //1
  {
    width: 1199,
    height: 800,
    left: 0,
    top: 0,
    opacity: 1,
    "border-radius": 0,
  },

  //2
  {
    width: 1199,
    height: 800,
    left: 0,
    top: 0,
    opacity: 0,
    "border-radius": 0,
  },
];

type PositionPhone = typeof positionsPhone[0];
type PositionTextBlockMain = typeof positionsTextBlockMain[0];
type PositionBackgroundMain = typeof positionsBackgroundMain[0];

const Phone = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 9;
  border-radius: 32px;
  box-sizing: border-box;
  background: white;
  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionPhone>(
      positionsPhone[currentAnimate - 3] ||
        positionsPhone[positionsPhone.length - 1]
    )};
  ${widthOrHeightInitial}
`;

const TextBlockMain = styled.div<PropsAnimate>`
  position: absolute;
  z-index: 2;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionTextBlockMain>(
      positionsTextBlockMain[currentAnimate - 4] ||
        positionsTextBlockMain[positionsTextBlockMain.length - 1]
    )};

  display: flex;
  flex-direction: column;

  ${H1} {
    margin-bottom: ${pxToPercentHeight(20)};
    font-size: 64px;
    line-height: 68px;
  }

  ${Body1} {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  > button {
    padding: 0;
    width: fit-content;
  }
`;

const BackgroundMain = styled.div<PropsAnimate>`
  position: absolute;
  background: black;
  z-index: 1;
  aspect-ratio: 1123/440;
  border-radius: 56px;

  ${transition}
  ${({ currentAnimate }) =>
    generateStyleAnimate<PositionBackgroundMain>(
      positionsBackgroundMain[currentAnimate - 4] ||
        positionsBackgroundMain[positionsBackgroundMain.length - 1]
    )};

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const ShowModel: FC<PropsAnimate> = ({ currentAnimate }) => {
  return (
    <>
      <Phone currentAnimate={currentAnimate}></Phone>

      <TextBlockMain currentAnimate={currentAnimate}>
        <H1>Посмотри модель</H1>

        <Body1>После обработки бот пришлёт ссылку на твою модель</Body1>
      </TextBlockMain>

      <BackgroundMain currentAnimate={currentAnimate} />
    </>
  );
};
