import { FC, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Image } from "@salutejs/plasma-b2c";
import { secondary } from "@salutejs/plasma-tokens-b2c";
import { BodyM, BodyXS, RectSkeleton } from "@salutejs/plasma-web";

import { useStateContext } from "../../utils/context";
import { IModel } from "../../utils/types";
import { SIZES } from "../../utils/breakpoints";

import { Avatar } from "../Avatar";

const StyledCard = styled.div<{ isAspectRatio: boolean }>`
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  padding: 0;
  overflow: hidden;

  ${({ isAspectRatio }) => (isAspectRatio ? "aspect-ratio: 230/320;" : "")}

  > video {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    > video {
      display: block;
    }
  }
`;

const StyledDescription = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.37) 100%
  );

  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;

  transform: scale(1);
  transition: 0.5s;
  opacity: 0;

  &:hover {
    transform: scale();
    opacity: 1;
  }

  @media (max-width: ${SIZES.sm}px) {
    opacity: 1;
  }

  ${BodyM} {
    text-align: left;
  }

  ${BodyXS} {
    color: ${secondary};
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  > img {
    height: 100%;
    object-fit: cover;
  }
`;

const StyledRectSkeleton = styled(RectSkeleton)`
  width: 100%;
  height: 100%;
`;

type CardProps = {
  model: IModel;
  isAspectRatio?: boolean;
};

export const SkeletonCard: FC = () => {
  return (
    <StyledCard isAspectRatio>
      <StyledRectSkeleton width={200} height={200} />
    </StyledCard>
  );
};

export const Card: FC<CardProps> = ({ model, isAspectRatio = true }) => {
  const [isShowVideo, setIsShowVideo] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { searchQuery } = useStateContext();

  const { nickname, uid, description, date, path_preview, path_train_video } =
    model;

  const handleModelPage = () => {
    const options = {
      searchQuery,
      back: pathname,
    };

    const params = new URLSearchParams(options);

    navigate(`/glb/${uid}?${params}`);
  };

  const shortDate = useMemo(
    () =>
      new Date(date).toLocaleDateString("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
    [date]
  );

  return (
    <StyledCard
      onClick={handleModelPage}
      isAspectRatio={isAspectRatio}
      onMouseEnter={() => setIsShowVideo(true)}
    >
      <StyledDescription>
        <BodyM>{description}</BodyM>

        <Avatar isCard nickname={nickname} src={nickname} />
        <BodyXS>{shortDate}</BodyXS>
      </StyledDescription>

      {path_train_video && isShowVideo && (
        <video
          autoPlay
          loop
          muted
          poster={`https://cdn.sxr.su/${path_preview}`}
          src={`https://cdn.sxr.su/${path_train_video}`}
        />
      )}

      <StyledImage
        alt="model preview"
        src={`https://cdn.sxr.su/${path_preview}`}
      />
    </StyledCard>
  );
};
